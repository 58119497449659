import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import {
  CurrentPromoter,
  useCurrentPromoterQuery,
  usePromoterManageQuery,
} from '@queries/PromoterQueries';
import { useUserTalentQuery } from '@queries/UserQueries';

interface CreatePromoterMutationPayload {
  title: string;
  description: string;
  cityIds: string[];
}

export interface CreatePromoterMutationResponseBody {
  promoter?: CurrentPromoter;
}

export const useCreatePromoterMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: CreatePromoterMutationPayload) => {
      return API.post<CreatePromoterMutationResponseBody, CreatePromoterMutationPayload>(
        '/v1/promoter/me',
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentPromoterQuery.queryKey() });
    },
  });

  return {
    isSubmitting,
    createPromoterAsync: mutateAsync,
  };
};

interface UpdatePromoterMutationPayload {
  title: string;
  description: string;
  cityIds: string[];
}

export const useUpdatePromoterMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: UpdatePromoterMutationPayload) => {
      return API.put<{}, UpdatePromoterMutationPayload>('/v1/promoter/me', payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentPromoterQuery.queryKey() });
    },
  });

  return {
    isSubmitting,
    updatePromoterAsync: mutateAsync,
  };
};

export interface CreatePromoterEventMutationPayload {
  eventImage: any;
  title: string;
  description: string;
  startDate: string;
  startTime: string;
  cityId: string;
  bookingOpportunityId?: string;
  isRecurring: boolean;
  recurringSchedule?: {
    type: 'weekly' | 'bi-weekly' | 'monthlyNthDay';
  };
  venue: {
    _id?: string;
    title: string;
  };
}

export const useCreatePromoterEventMutation = (promoterId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: CreatePromoterEventMutationPayload) => {
      return API.post<{}, CreatePromoterEventMutationPayload>(
        `/v1/user/promoter/${promoterId}/events`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usePromoterManageQuery.queryKey(promoterId) });
    },
  });

  return {
    isSubmitting,
    createPromoterEventAsync: mutateAsync,
  };
};

export interface CreateBookingOpportunityMutationPayload {
  title: string;
  details: string;
  startDate: string;
  startTime: string;
  cityId: string;
  venue: {
    _id?: string;
    title: string;
  };
}

export const useCreateBookingOpportunityMutation = (promoterId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: CreateBookingOpportunityMutationPayload) => {
      return API.post<{}, CreateBookingOpportunityMutationPayload>(
        `/v1/user/promoter/${promoterId}/booking-opportunities`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usePromoterManageQuery.queryKey(promoterId) });
    },
  });

  return {
    isSubmitting,
    createBookingOpportunityAsync: mutateAsync,
  };
};

export interface CreateNoAuthBookingOpportunityMutationPayload {
  title: string;
  details: string;
  startDate: string;
  startTime: string;
  cityId: string;
  venue: {
    _id?: string;
    title: string;
  };
  contact: {
    name: string;
    email: string;
    phone: string;
    organization: {
      type: string;
      title: string;
    };
  };
}

export const useNoAuthCreateBookingOpportunityMutation = () => {
  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: CreateNoAuthBookingOpportunityMutationPayload) => {
      return API.post<{}, CreateNoAuthBookingOpportunityMutationPayload>(
        `/v1/booking-opportunities`,
        payload,
      );
    },
  });

  return {
    isSubmitting,
    createNoAuthBookingOpportunityAsync: mutateAsync,
  };
};

const useInvalidateBookingOpportunityQueries = () => {
  const queryClient = useQueryClient();

  return {
    invalidateBookingOpportunityQueries: (promoterId?: string) => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: useUserTalentQuery.queryKey(),
        }),
        queryClient.invalidateQueries({
          queryKey: usePromoterManageQuery.queryKey(promoterId),
        }),
      ]);
    },
  };
};

export interface UpdatePromoterBookingOpportunityEntertainerMutationPayload {
  entertainers: { [entertainerId: string]: { status: 'confirmed' | 'rejected' } };
}

export const useUpdatePromoterBookingOpportunityEntertainerMutation = (promoterId: string) => {
  const { invalidateBookingOpportunityQueries } = useInvalidateBookingOpportunityQueries();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: ({
      bookingOpportunityId,
      entertainerId,
      status,
    }: {
      bookingOpportunityId: string;
      entertainerId: string;
      status: 'confirmed' | 'rejected';
    }) => {
      return API.post<{}, UpdatePromoterBookingOpportunityEntertainerMutationPayload>(
        `/v1/user/booking-opportunities/${bookingOpportunityId}/entertainers`,
        { entertainers: { [entertainerId]: { status } } },
      );
    },
    onSuccess: () => {
      invalidateBookingOpportunityQueries(promoterId);
    },
  });

  return {
    isSubmitting,
    updatePromoterBookingOpportunityEntertainerAsync: mutateAsync,
  };
};

export interface AddPromoterBookingOpportunityUpdateMutationPayload {
  message: string;
}

export const useAddPromoterBookingOpportunityUpdateMutation = (promoterId: string) => {
  const { invalidateBookingOpportunityQueries } = useInvalidateBookingOpportunityQueries();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: ({
      bookingOpportunityId,
      message,
    }: AddPromoterBookingOpportunityUpdateMutationPayload & {
      bookingOpportunityId: string;
    }) => {
      return API.post<{}, AddPromoterBookingOpportunityUpdateMutationPayload>(
        `/v1/user/booking-opportunities/${bookingOpportunityId}/add-update`,
        { message },
      );
    },
    onSuccess: () => {
      invalidateBookingOpportunityQueries(promoterId);
    },
  });

  return {
    isSubmitting,
    addPromoterBookingOpportunityUpdateAsync: mutateAsync,
  };
};

export interface ConfirmPromoterBookingOpportunityEntertainerMutationPayload {}

export const useConfirmPromoterBookingOpportunityEntertainerMutation = (promoterId: string) => {
  const { invalidateBookingOpportunityQueries } = useInvalidateBookingOpportunityQueries();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ bookingOpportunityId }: { bookingOpportunityId: string }) => {
      return API.post<{}, ConfirmPromoterBookingOpportunityEntertainerMutationPayload>(
        `/v1/user/booking-opportunities/${bookingOpportunityId}/confirm`,
        {},
      );
    },
    onSuccess: () => {
      invalidateBookingOpportunityQueries(promoterId);
    },
  });

  return {
    isConfirmingPromoterBookingOpportunityEntertainers: isPending,
    confirmPromoterBookingOpportunityEntertainersAsync: mutateAsync,
  };
};
