import { ComponentType, FC, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import dayjs from 'dayjs';
import { sum } from 'lodash';

import { Venue } from '@queries/VenueQueries';

import {
  CritiqueDocumentMutationPayload,
  useAnswerCritiqueQuestionMutation,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import { PostCardHeaderWrapper } from '@components/cards/PostCard/PostCard';
import Card from '@components/layout/Card/Card';
import { emptyChartProps } from '@components/layout/DetailsChartSection/DetailsChartSection';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { CritiquePopupButton } from './CritiquePopupButton';

export const YES_NO_COLOR_RECORD: Record<'yes' | 'no', string> = {
  yes: '#4cd964',
  no: '#ff3b30',
};

export const CritiqueQuestionCard: FC<{
  post: {
    questionId: string;
    document: {
      _id: string;
      title: string;
    };
    documentType: string;
    question: string;
    createdAt: string;
    answers: {
      yes: number;
      no: number;
    };
  };
}> = ({ post }) => {
  const { track } = useAnalytics();
  const [answer, setAnswer] = useState<'yes' | 'no'>();
  const { createAuthGateHandler } = useAuthGate();
  const { answerCritiqueQuestionAsync } = useAnswerCritiqueQuestionMutation();

  const answers = useMemo<Record<string, number>>(() => {
    return Object.fromEntries(
      Object.entries(post.answers).map(([key, value]: [string, number]) => [
        key,
        key === answer ? value + 1 : value,
      ]),
    );
  }, [post.answers, answer]);

  const handleAnswerQuestion = (updatedAnswer: 'yes' | 'no') => {
    createAuthGateHandler(async () => {
      track('Answer Critique Question Card', {
        critiqueQuestionId: post.questionId,
        venue: post.document.title,
        answer: updatedAnswer,
      });

      setAnswer(updatedAnswer);

      await answerCritiqueQuestionAsync({
        critiqueQuestionId: post.questionId,
        documentId: post.document._id,
        answer: updatedAnswer,
      });
    });
  };

  const pieChartData: { key: string; value: number; color: string }[] = useMemo(() => {
    const total = sum(Object.values(answers));

    return Object.entries(answers)
      .filter(([, value]) => value)
      .map(([key, value]: [string, number]) => ({
        key,
        value: Math.round((value / total) * 100),
        color: YES_NO_COLOR_RECORD[key],
      }));
  }, [answers]);

  return (
    <Card>
      <PostCardHeaderWrapper
        wrapperTitle="Quick Critique"
        title={post.document.title}
        createdAt={dayjs(post.createdAt).toISOString()}
        action={<CritiquePopupButton document={post.document} documentType={post.documentType} />}
      />
      <Card.Body>
        <div className="d-flex gap-3">
          <div style={{ width: '33.3333%' }}>
            <div>
              <PieChart
                rounded
                lineWidth={25}
                radius={50}
                data={pieChartData}
                paddingAngle={25}
                {...(pieChartData.length > 0 ? {} : emptyChartProps)}
                // label={({ dataEntry }) => `${dataEntry.value}%`}
                // labelStyle={(index) => ({
                //   fill: pieChartData[index].color,
                //   fontSize: '12px',
                //   fontWeight: 'bold',
                //   fontFamily: 'Rubik',
                //   letterSpacing: 1,
                // })}
              />
            </div>
          </div>
          <div style={{ width: '66.6666%' }}>
            <div style={{ fontSize: 16 }}>
              <strong>{post.question}</strong>
            </div>
            <div className="d-flex gap-2 w-100 mt-2">
              <Button
                variant={answer === 'yes' ? 'default' : 'outlined'}
                color="neutral"
                roundness="rounded"
                onClick={() => handleAnswerQuestion('yes')}
              >
                Yes
              </Button>
              <Button
                variant={answer === 'no' ? 'default' : 'outlined'}
                color="neutral"
                roundness="rounded"
                onClick={() => handleAnswerQuestion('no')}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
