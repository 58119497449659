import { f7ready } from 'framework7-react';

export const useDialog = () => {
  const openAlertDialog = ({
    title,
    text,
    cb,
  }: {
    title: string;
    text: string;
    cb?: () => void;
  }) => {
    f7ready((f7) => {
      f7.dialog.alert(text, title, cb);
    });
  };

  const openConfirmDialog = ({
    title,
    text,
    onConfirm,
    buttonCancel = 'Cancel',
    buttonOk = 'Ok',
  }: {
    title: string;
    text: string;
    onConfirm?: () => void;
    buttonCancel?: string;
    buttonOk?: string;
  }) => {
    f7ready((f7) => {
      const dialog = f7.dialog.create({
        text,
        title,
        buttons: [
          {
            text: buttonCancel,
          },
          {
            text: buttonOk,
            onClick: onConfirm,
            strong: true,
          },
        ],
      });

      dialog.open();
    });
  };

  return { openAlertDialog, openConfirmDialog };
};
