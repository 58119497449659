import { createContext, useContext, useMemo, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import {
  Entertainer,
  EntertainerRank,
  useCurrentUserEntertainerCritiqueQuery,
  useEntertainerByBookingHandleQuery,
  useEntertainerByIdQuery,
} from '@queries/EntertainerQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
  useTopEntertainersOptimisticUpdate,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { EntertainerCritiquePopup } from './EntertainerCritiquePopup';

const EntertainerCritiquePopupContext = createContext<{
  openEntertainerCritiquePopup: (entertainerId: string) => void;
}>({ openEntertainerCritiquePopup: () => {} });
export const useEntertainerCritiquePopup = () => useContext(EntertainerCritiquePopupContext);

export const EntertainerCritiquePopupProvider = ({ children }) => {
  const { userIsLoggedIn } = useAppContext();
  const { currentCity } = useCurrentUserCity();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();
  const { track } = useAnalytics();
  const [entertainerId, setEntertainerId] = useState<string>();

  const { entertainer, entertainerIsReady } = useEntertainerByIdQuery(entertainerId);

  const { userEntertainerCritiqueIsReady, userEntertainerCritique } =
    useCurrentUserEntertainerCritiqueQuery(entertainerId);
  const { handleTopEntertainersOptimisticUpdate } = useTopEntertainersOptimisticUpdate(
    currentCity?._id,
    entertainer?.type,
    userIsLoggedIn,
  );

  const selectedEntertainer = useMemo<EntertainerRank | undefined>(() => {
    if (entertainerIsReady && entertainer && entertainer._id === entertainerId) {
      const {
        _id,
        title,
        tags = {
          like: [],
          neutral: [],
          dislike: [],
        },
        bookingHandle,
      } = entertainer;

      if (userIsLoggedIn) {
        if (userEntertainerCritiqueIsReady && userEntertainerCritique) {
          const {
            like = [],
            neutral = [],
            dislike = [],
            primaryCritique: primaryRating,
          } = userEntertainerCritique || {};

          return {
            _id,
            title,
            tags,
            bookingHandle,
            rank: 0,
            like: like.map((label) => ({ label })),
            neutral: neutral.map((label) => ({ label })),
            dislike: dislike.map((label) => ({ label })),
            primaryRating,
          };
        }
      } else {
        return {
          _id,
          title,
          tags,
          bookingHandle,
          rank: 0,
          like: [],
          neutral: [],
          dislike: [],
          primaryRating: undefined,
        };
      }
    }

    return undefined;
  }, [entertainer, userEntertainerCritique, entertainerIsReady, entertainerId, userIsLoggedIn]);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleClickRating =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Click rate entertainer', {
        ...payload,
        entertainerId,
      });
      createAuthGateHandler(async () => {
        handleTopEntertainersOptimisticUpdate(entertainerId, {
          primaryCritique: payload.primaryCritique,
        });
        await critiqueDocumentAsync({
          ...payload,
          documentType: 'entertainers',
          documentId: entertainerId,
        });
      });
    };

  const handleUpdateStrengths =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Update strengths', {
        ...payload,
        entertainerId,
      });
      createAuthGateHandler(async () => {
        handleTopEntertainersOptimisticUpdate(entertainerId, {
          like: payload.like,
        });
        await critiqueDocumentAsync({
          ...payload,
          documentType: 'entertainers',
          documentId: entertainerId,
        });
      });
    };

  const handleUpdateImprovements =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Update improvements', {
        ...payload,
        entertainerId,
      });
      createAuthGateHandler(async () => {
        handleTopEntertainersOptimisticUpdate(entertainerId, {
          neutral: payload.neutral,
        });
        await critiqueDocumentAsync({
          ...payload,
          documentType: 'entertainers',
          documentId: entertainerId,
        });
      });
    };

  const handleNavigateToEntertainer = (entertainer: EntertainerRank) => {
    navigate(`/noauth/entertainer/${entertainer.bookingHandle}`);
  };

  const openEntertainerCritiquePopup = (entertainerId: string) => {
    setEntertainerId(entertainerId);
  };

  return (
    <EntertainerCritiquePopupContext.Provider value={{ openEntertainerCritiquePopup }}>
      {children}
      <EntertainerCritiquePopup
        selectedEntertainer={selectedEntertainer}
        onClickRating={handleClickRating}
        onUpdateStrengths={handleUpdateStrengths}
        onUpdateImprovements={handleUpdateImprovements}
        onClickViewProfile={handleNavigateToEntertainer}
        onClose={() => setEntertainerId(undefined)}
      />
    </EntertainerCritiquePopupContext.Provider>
  );
};
