import { List, ListItem } from 'framework7-react';
import { ComponentProps, FC, Fragment, useLayoutEffect, useMemo, useRef, useState } from 'react';

import * as dayjs from 'dayjs';
import { capitalize, keyBy } from 'lodash';

import {
  AskedDetailQuestion,
  BookingOpportunityEntertainer,
  PublicBookingOpportunity,
  useCurrentPromoterQuery,
  usePromoterManageQuery,
} from '@queries/PromoterQueries';
import { useDetailQuestionsQuery, useUserTalentQuery } from '@queries/UserQueries';

import {
  useAddPromoterBookingOpportunityUpdateMutation,
  useConfirmPromoterBookingOpportunityEntertainerMutation,
  useUpdatePromoterBookingOpportunityEntertainerMutation,
} from '@mutations/PromoterMutations';
import {
  useAskDetailQuestionMutation,
  useRespondDetailQuestionMutation,
} from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { ShareLinkButton } from '@components/buttons/ShareLinkButton';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { List as AdvancedList } from '@components/layout/List/List';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

export const BookingOpportunityUpdatesSection = ({
  canAddUpdate,
  bookingOpportunity,
}: {
  canAddUpdate: boolean;
  bookingOpportunity: Pick<PublicBookingOpportunity, '_id' | 'promoter' | 'updates'>;
}) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const { isSubmitting, addPromoterBookingOpportunityUpdateAsync } =
    useAddPromoterBookingOpportunityUpdateMutation(bookingOpportunity?.promoter?._id);

  const handleSubmit = async () => {
    await addPromoterBookingOpportunityUpdateAsync({
      bookingOpportunityId: bookingOpportunity._id,
      message: newMessage,
    });
  };

  const hasUpdates = !!bookingOpportunity.updates.length;

  return (
    <div>
      <TitleToolbar
        text="Updates 🚨"
        size="md"
        action={
          hasUpdates &&
          canAddUpdate && (
            <Button variant="flat" onClick={() => setShowTextarea((prevValue) => !prevValue)}>
              {!showTextarea ? 'Add Update' : 'Hide'}
            </Button>
          )
        }
      />

      {(!hasUpdates || showTextarea) && canAddUpdate && (
        <Card>
          <Textarea
            onChange={setNewMessage}
            submitButton={{ text: 'Share Update', onClick: handleSubmit, disabled: isSubmitting }}
          />
        </Card>
      )}

      {!canAddUpdate && !hasUpdates ? (
        <EmptyState
          title="No updates"
          text="There are currently no updates for this booking opportunity."
        />
      ) : (
        <>
          {bookingOpportunity.updates.map((update) => (
            <Card key={update.message}>
              <Card.Header
                title={bookingOpportunity?.promoter?.title}
                subtitle={`Posted ${dayjs(update.createdAt).fromNow()}`}
              />
              <Card.Body>{mapStringToParagraphs(update.message)}</Card.Body>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

interface AskDetailQuestionComponentProps {
  detailQuestionId: string;
  question: string;
  answer?: string;
  bookingOpportunityId: string;
}

const AskDetailQuestionButtonListItem: FC<AskDetailQuestionComponentProps> = ({
  detailQuestionId,
  question,
  answer,
  bookingOpportunityId,
}) => {
  const isAwaitingResponse = !!answer;
  const [didSend, setDidSend] = useState(false);
  const { askDetailQuestionAsync } = useAskDetailQuestionMutation();

  const handleAskDetailQuestion = async () => {
    await askDetailQuestionAsync({
      detailQuestionId,
      documentType: 'bookingOpportunity',
      documentId: bookingOpportunityId,
    });
    setDidSend(true);
  };

  const buttonText = useMemo(() => {
    if (didSend) {
      return 'Asked';
    }

    if (isAwaitingResponse) {
      return 'Waiting...';
    }

    return 'Ask';
  }, [didSend]);

  const button = useMemo(() => {
    if (answer) {
      return;
    }

    return (
      <div slot="after">
        <div className="ps-2">
          <Button
            size="md"
            className="py-2 px-3"
            roundness="rounded"
            color="neutral"
            variant="outlined"
            disabled={didSend || isAwaitingResponse}
            onClick={handleAskDetailQuestion}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  }, [didSend, isAwaitingResponse, buttonText, answer]);

  return (
    <ListItem
      title={(<span style={{ whiteSpace: 'normal' }}>{question}</span>) as any}
      footer={answer}
    >
      {button}
    </ListItem>
  );
};

const RespondDetailQuestionListItem: FC<{
  askedDetailQuestion?: AskedDetailQuestion;
  options: string[];
  bookingOpportunityId: string;
}> = ({ askedDetailQuestion, options, bookingOpportunityId }) => {
  const { answer, question, detailQuestionId } = askedDetailQuestion ?? ({} as any);
  const [didSend, setDidSend] = useState(false);
  const [answerOverride, setAnswerOverride] = useState(answer);
  const { respondDetailQuestionAsync } = useRespondDetailQuestionMutation();

  const handleAskDetailQuestion = async (answer: string) => {
    await respondDetailQuestionAsync({
      detailQuestionId,
      documentType: 'bookingOpportunity',
      documentId: bookingOpportunityId,
      answer,
    });
    setAnswerOverride(answer);
    setDidSend(true);
  };

  const button = useMemo(() => {
    if (answer) {
      return <div slot="after" />;
    }

    if (didSend) {
      return (
        <div slot="after">
          <Button
            size="md"
            className="py-2 px-3"
            roundness="rounded"
            color="neutral"
            variant="outlined"
            disabled={didSend}
          >
            Answered
          </Button>
        </div>
      );
    }

    return (
      <div slot="after">
        <PopoverWrapper
          PopoverContent={({ onClose }) => (
            <div>
              <div style={{ height: 160, overflow: 'auto' }}>
                {options?.length && (
                  <AdvancedList
                    listItems={
                      options?.map((reason) => ({
                        title: reason,
                        onClick: () => {
                          handleAskDetailQuestion(reason);
                          onClose();
                        },
                      })) ?? []
                    }
                  />
                )}
              </div>
            </div>
          )}
        >
          <div className="ps-2">
            <Button
              size="md"
              className="py-2 px-3"
              roundness="rounded"
              color="neutral"
              variant="outlined"
              endIcon={<FontAwesomeIcon name="chevron-down" />}
            >
              Respond
            </Button>
          </div>
        </PopoverWrapper>
      </div>
    );
  }, [didSend, answer, options]);

  return (
    <ListItem
      title={(<span style={{ whiteSpace: 'normal' }}>{question}</span>) as any}
      footer={answerOverride ?? answer}
    >
      {button}
    </ListItem>
  );
};

const MAX_QUESTIONS = 5;
export const BookingOpportunityDetailQuestionsSection = ({
  className,
  mode,
  bookingOpportunity,
}: {
  className?: string;
  mode: 'ask' | 'respond';
  bookingOpportunity: Pick<PublicBookingOpportunity, '_id' | 'promoter' | 'askedDetailQuestions'>;
}) => {
  const [showAll, setShowAll] = useState(false);
  const { detailQuestionsByDocumentType } = useDetailQuestionsQuery();
  const askedDetailQuestionsByQuestionId = keyBy(
    bookingOpportunity.askedDetailQuestions,
    'detailQuestionId',
  );
  const bookingDetailQuestionsById = keyBy(detailQuestionsByDocumentType?.booking, '_id');

  return (
    <div className={className}>
      <TitleToolbar
        text={`${mode === 'ask' ? 'Ask' : 'Answer'} Additional Questions 💭`}
        size="md"
      />
      <List
        strongIos
        outlineIos
        dividersIos
        className="mt-2 mb-0"
        style={{ marginLeft: '-1rem', marginRight: '-1rem' }}
      >
        {mode === 'ask' ? (
          <ul>
            {detailQuestionsByDocumentType?.booking
              ?.map((detailQuestion) => (
                <AskDetailQuestionButtonListItem
                  key={detailQuestion._id}
                  detailQuestionId={detailQuestion._id}
                  question={detailQuestion.question}
                  answer={askedDetailQuestionsByQuestionId[detailQuestion._id]?.answer}
                  bookingOpportunityId={bookingOpportunity._id}
                />
              ))
              .slice(0, showAll ? undefined : MAX_QUESTIONS)}
          </ul>
        ) : (
          <ul>
            {Object.values(askedDetailQuestionsByQuestionId).map(
              (askedDetailQuestion: AskedDetailQuestion) => (
                <RespondDetailQuestionListItem
                  askedDetailQuestion={askedDetailQuestion}
                  options={bookingDetailQuestionsById[askedDetailQuestion._id]?.options ?? []}
                  bookingOpportunityId={bookingOpportunity._id}
                />
              ),
            )}
          </ul>
        )}
      </List>
      {!showAll && detailQuestionsByDocumentType?.booking?.length > MAX_QUESTIONS && (
        <Button variant="flat" color="neutral" onClick={() => setShowAll(true)}>
          See all questions
        </Button>
      )}
    </div>
  );
};

const EntertainerOption: FC<{
  isStatusUpdateDisabled?: boolean;
  promoterId?: string;
  bookingOpportunityId: string;
  bookingOpportunityEntertainer: BookingOpportunityEntertainer;
}> = ({
  isStatusUpdateDisabled,
  promoterId,
  bookingOpportunityId,
  bookingOpportunityEntertainer,
}) => {
  const navigate = useNavigate();
  const { entertainer } = bookingOpportunityEntertainer;
  const [status, setStatus] = useState(bookingOpportunityEntertainer.status);
  const { isSubmitting, updatePromoterBookingOpportunityEntertainerAsync } =
    useUpdatePromoterBookingOpportunityEntertainerMutation(promoterId);

  const CONTACT_METHODS = {
    email: {
      startIcon: <FontAwesomeIcon name="envelope" />,
      label: 'Email',
      onClick: () => openExternalUrl(`mailto:${entertainer.contact?.email}`),
    },
    messenger: {
      startIcon: <FontAwesomeIcon faStyle="fab" name="facebook-messenger" />,
      label: 'Messenger',
      onClick: () => openExternalUrl(`https://m.me/${entertainer.contact?.facebook}`),
    },
    instagram: {
      startIcon: <FontAwesomeIcon faStyle="fab" name="instagram" />,
      label: 'Instagram',
      onClick: () => openExternalUrl(`https://instagram.com/${entertainer.contact?.instagram}`), // openExternalUrl(`instagram://user?username=`),
    },
  };

  const handleChangeStatus = async (status: 'confirmed' | 'rejected') => {
    await updatePromoterBookingOpportunityEntertainerAsync({
      bookingOpportunityId,
      entertainerId: bookingOpportunityEntertainer.entertainer._id,
      status,
    });
  };

  const statusButton = (
    <Button
      disabled={isStatusUpdateDisabled}
      className="py-2 px-3"
      roundness="rounded"
      variant="outlined"
      color="neutral"
      size="sm"
      endIcon={<FontAwesomeIcon name="chevron-down" />}
    >
      {status === 'notInterested' ? 'Not Interested' : capitalize(status)}
    </Button>
  );

  const handleNavigateToEntertainer = () => {
    if (entertainer.bookingHandle) {
      navigate(`/noauth/entertainer/${entertainer.bookingHandle}`, {
        state: {
          back: `/manage/promoter/open-bookings/${bookingOpportunityId}`,
        },
      });
    }
  };

  return (
    <Card>
      <Card.Header
        title={entertainer.title ?? 'Unknown Entertainer'}
        onTitleClick={handleNavigateToEntertainer}
        action={
          isStatusUpdateDisabled ? (
            statusButton
          ) : (
            <PopoverWrapper
              PopoverContent={({ onClose }) => (
                <div className="p-2 d-flex gap-2">
                  <Button
                    disabled={isSubmitting}
                    roundness="rounded"
                    onClick={() => {
                      setStatus('confirmed');
                      handleChangeStatus('confirmed');
                      onClose();
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    roundness="rounded"
                    color="neutral"
                    variant="outlined"
                    onClick={() => {
                      setStatus('rejected');
                      handleChangeStatus('rejected');
                      onClose();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            >
              {statusButton}
            </PopoverWrapper>
          )
        }
      />
      {entertainer.avatar && (
        <div onClick={handleNavigateToEntertainer}>
          <AsyncImage
            src={entertainer.avatar}
            alt={entertainer.title}
            style={{ aspectRatio: 1, objectFit: 'cover' }}
          />
        </div>
      )}
      {entertainer.tagline && <Card.Body>{mapStringToParagraphs(entertainer.tagline)}</Card.Body>}
      <Card.Footer className="gap-2">
        <Button
          startIcon={<FontAwesomeIcon name="comment" />}
          roundness="rounded"
          variant="smooth"
          color="neutral"
          className="px-2"
          onClick={() => navigate(`/chat/promoter/${promoterId}/entertainer/${entertainer._id}`)}
        >
          Message
        </Button>
        {Object.keys(entertainer.contact ?? {})?.length ? (
          Object.entries(CONTACT_METHODS)
            .filter(([key]) => entertainer?.contact?.[key])
            .map(([key, { startIcon, label, onClick }]) => (
              <Button
                key={key}
                startIcon={startIcon}
                roundness="rounded"
                variant="smooth"
                color="neutral"
                className="px-2"
                onClick={onClick}
              >
                {label}
              </Button>
            ))
        ) : (
          <Button className="opacity-75" roundness="rounded" variant="outlined" color="neutral">
            No Contact Info
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export const ShareBookingOpportunityButton: FC<{
  bookingOpportunityId: string;
  buttonProps?: Partial<ComponentProps<typeof Button>>;
}> = ({ bookingOpportunityId, buttonProps }) => {
  return (
    <ShareLinkButton
      url={`https://my.gaggl.app/noauth/booking-opportunity/${bookingOpportunityId}`}
      buttonProps={{ className: 'py-2 opacity-90', ...buttonProps }}
      track={{ event: 'Click share booking opportunity' }}
    />
  );
};

export const ManagePromoterBookingOpportunityScreen: FC<{ back?: string }> = ({ back }) => {
  const { bookingOpportunityId } = useParams<{ bookingOpportunityId?: string }>();
  const { promoterIsReady, promoter: currentPromoter } = useCurrentPromoterQuery();
  const { promoterDetails, promoterDetailsAreReady } = usePromoterManageQuery(currentPromoter?._id);
  const navigate = useNavigate();
  const { talentIsReady, talent } = useUserTalentQuery();
  const { track } = useAnalytics();

  const bookingOpportunity = useMemo(() => {
    return (
      promoterDetails?.bookingOpportunities.find(({ _id }) => _id === bookingOpportunityId) ??
      talent?.bookingOpportunities.find(({ _id }) => _id === bookingOpportunityId)
    );
  }, [promoterDetails?.bookingOpportunities, talent?.bookingOpportunities, bookingOpportunityId]);

  const { confirmPromoterBookingOpportunityEntertainersAsync } =
    useConfirmPromoterBookingOpportunityEntertainerMutation(currentPromoter?._id);

  const handleConfirmEntertainers = async () => {
    await confirmPromoterBookingOpportunityEntertainersAsync({ bookingOpportunityId });
  };

  const handleCreateEvent = async () => {
    navigate(`/manage/promoter/booking-opportunities/${bookingOpportunityId}/event`);
  };

  const filteredEntertainers = useMemo(() => {
    if (bookingOpportunity?.status === 'open') {
      return bookingOpportunity?.entertainers ?? [];
    }

    return bookingOpportunity?.entertainers?.filter(({ status }) => status === 'confirmed') ?? [];
  }, [bookingOpportunity?.status, bookingOpportunity?.entertainers]);

  return (
    <Screen
      name="Manage Promoter Booking Opportunity Screen"
      headerProps={{
        back: { to: '/manage/promoter' },
        title: 'Manage Opportunity',
        right: <ShareBookingOpportunityButton bookingOpportunityId={bookingOpportunityId} />,
      }}
      bottomToolbar={
        <Screen.BottomToolbar maxWidth={425}>
          {bookingOpportunity?.status === 'open' ? (
            <Button roundness="rounded" onClick={handleConfirmEntertainers}>
              Confirm Entertainers
            </Button>
          ) : (
            <Button
              roundness="rounded"
              disabled={!!bookingOpportunity?.eventId}
              onClick={handleCreateEvent}
            >
              {bookingOpportunity?.eventId ? 'Event Created' : 'Create Event'}
            </Button>
          )}
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content maxWidth={425}>
        {promoterDetailsAreReady ? (
          <>
            {bookingOpportunity && (
              <div className="grid">
                <div>
                  <TitleToolbar className="title-toolbar-welcome" text={bookingOpportunity.title} />
                  <div className="d-flex gap-2">
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="clock" />
                      </span>
                      {dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY')}
                    </div>
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="map-marker" />
                      </span>
                      {`${bookingOpportunity.venue.title}, ${bookingOpportunity.city.name}`}
                    </div>
                  </div>

                  {mapStringToParagraphs(bookingOpportunity.details)}
                </div>
                <BookingOpportunityUpdatesSection
                  canAddUpdate
                  bookingOpportunity={bookingOpportunity}
                />

                <BookingOpportunityDetailQuestionsSection
                  mode="respond"
                  bookingOpportunity={bookingOpportunity}
                />

                <TitleToolbar text="Entertainers 🪭" size="md" />
                {filteredEntertainers.length ? (
                  <>
                    {filteredEntertainers.map((bookingOpportunityEntertainer) => (
                      <EntertainerOption
                        key={bookingOpportunityEntertainer.entertainer._id}
                        promoterId={currentPromoter?._id}
                        isStatusUpdateDisabled={bookingOpportunity.status !== 'open'}
                        bookingOpportunityId={bookingOpportunity._id}
                        bookingOpportunityEntertainer={bookingOpportunityEntertainer}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {bookingOpportunity.status === 'open' && (
                      <EmptyState
                        title="No interested entertainers"
                        text="There were currently no interested entertainers for this open booking."
                      />
                    )}
                    {bookingOpportunity.status === 'closed' && (
                      <EmptyState
                        title="No entertainers"
                        text="This opportunity has been closed and no entertainers were confirmed."
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <Skeleton height={200} />
        )}
      </Screen.Content>
    </Screen>
  );
};
