import { useQuery } from '@tanstack/react-query';
import { FC, ReactElement, useMemo, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useCurrentUser } from '@queries/UserQueries';

import Button from '@components/buttons/Button';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { AsyncImage } from '@components/layout/AsyncImg';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { EntertainerOnboardingLinkButton } from '../EntertainerDetailsScreen/EntertainerDetailsScreen';

interface FeaturedLook {
  _id: string;
  title: string;
  image: string | undefined;
  entertainer: {
    _id: string;
    title: string;
    bookingHandle: string;
  };
}

const useFeaturedLooksQuery = () => {
  const { data, isFetched } = useQuery({
    queryKey: ['featuredLooks'],
    queryFn: () => API.get<{ featuredLooks: FeaturedLook[] }>(`/v1/entertainer-media/featured`),
  });

  return {
    featuredLooks: data?.featuredLooks,
    featuredLooksAreReady: isFetched,
  };
};

export const FeaturedLookPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
  entertainerMedia: {
    _id: string;
    title: string;
    image: string;
  };
  entertainer: {
    _id: string;
    title: string;
  };
  header?: ReactElement;
}> = ({ entertainerMedia, entertainer, isVisible, onClose, header }) => {
  return (
    <Popup isVisible={isVisible} onClose={onClose} title="Featured Look" height="full">
      <Popup.Content>
        {entertainerMedia && (
          <>
            {header}
            <div style={{ marginBottom: '-.5rem' }}>{entertainer.title}</div>
            <TitleToolbar className="mt-0" text={entertainerMedia.title} />
            <div style={{ position: 'relative' }}>
              {false && (
                <div
                  className="p-3"
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    background: 'rgba(0,0,0,.6)',
                  }}
                >
                  <div style={{ marginBottom: '-.5rem' }}>{entertainer.title}</div>
                  <TitleToolbar className="m-0" text={entertainer.title} />
                </div>
              )}
              <AsyncImage src={entertainerMedia.image} style={{ width: '100%' }} />
              <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <div className="p-3">
                  <SendEntertainerBadgeButton
                    entertainerId={entertainer._id}
                    entertainerMediaId={entertainerMedia._id}
                    background="rgba(0,0,0,.6)"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Popup.Content>
    </Popup>
  );
};

export const FeaturedLooksScreen: FC<{}> = () => {
  const { featuredLooksAreReady, featuredLooks = [] } = useFeaturedLooksQuery();
  const { entertainer } = useCurrentEntertainerQuery();

  const [currentLookIndex, setCurrentLookIndex] = useState(-1);
  const currentFeaturedLook = useMemo<FeaturedLook | undefined>(() => {
    return featuredLooks?.[currentLookIndex];
  }, [currentLookIndex, featuredLooks]);

  return (
    <Screen
      name="Featured Looks Screen"
      headerProps={{
        title: <Screen.GagglTitle />,
      }}
      hideToolbar
      disableLocationPermissionGate
    >
      <Screen.Content maxWidth={425}>
        {!entertainer?.title && <EntertainerOnboardingLinkButton back="/noauth/featured-looks" />}
        <TitleToolbar text="Featured Looks 👀" />
        {featuredLooksAreReady ? (
          <div className="d-grid grid-cols-3 gap-1">
            {featuredLooks.map(({ _id, image }, i) => (
              <div
                key={_id}
                style={{ aspectRatio: 1 }}
                onClick={() => {
                  setCurrentLookIndex(i);
                }}
              >
                <AsyncImage
                  src={image}
                  style={{
                    display: 'block',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <Skeleton height={400} />
        )}
        <FeaturedLookPopup
          header={
            <div className="d-flex justify-content-between mb-3">
              <div>
                {currentLookIndex > 0 && (
                  <Button
                    variant="smooth"
                    color="neutral"
                    roundness="rounded"
                    onClick={() => setCurrentLookIndex((prevValue) => prevValue - 1)}
                  >
                    Previous
                  </Button>
                )}
              </div>
              <div>
                {currentLookIndex + 1 < featuredLooks.length && (
                  <Button
                    variant="smooth"
                    color="neutral"
                    roundness="rounded"
                    onClick={() => setCurrentLookIndex((prevValue) => prevValue + 1)}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          }
          entertainerMedia={currentFeaturedLook}
          entertainer={currentFeaturedLook?.entertainer}
          isVisible={!!currentFeaturedLook}
          onClose={() => setCurrentLookIndex(-1)}
        />
      </Screen.Content>
    </Screen>
  );
};
