import { FC, useState } from 'react';

import { event } from 'cypress/types/jquery';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

const useCopyText = () => {
  const [didCopy, setDidCopy] = useState(false);

  const copyText = async ({ text }: { text: string }) => {
    await navigator.clipboard.writeText(text);
    setDidCopy(true);

    setTimeout(() => {
      setDidCopy(false);
    }, 3000);
  };

  return { didCopy, copyText };
};

export const CopyText: FC<{
  text: string;
  track?: {
    event: string;
    properties?: Record<string, any>;
  };
}> = ({ text, track: trackProperties }) => {
  const { track } = useAnalytics();
  const { didCopy, copyText } = useCopyText();

  const handleClick = () => {
    if (trackProperties) {
      track(trackProperties.event, trackProperties.properties);
    }

    copyText({ text });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-between ps-3"
      style={{
        border: '1px solid rgba(255,255,255,.5)',
        borderRadius: '.5rem',
        background: 'rgba(255,255,255,.1)',
      }}
    >
      <span>{text}</span>
      <div>
        <Button
          startIcon={didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share" />}
          roundness="rounded"
          variant="flat"
          color="neutral"
          size="sm"
          onClick={handleClick}
        >
          {didCopy ? 'Copied!' : 'Copy'}
        </Button>
      </div>
    </div>
  );
};
