import { useQueryClient } from '@tanstack/react-query';
import { AccordionContent, List, ListItem } from 'framework7-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { useAppContext } from '@contexts/AppContext';
import { sum } from 'lodash';
import ordinal from 'ordinal';
import pluralize from 'pluralize';

import {
  Entertainer,
  EntertainerRank,
  TYPES_RECORD,
  useCurrentEntertainerQuery,
  useTopEntertainersQuery,
} from '@queries/EntertainerQueries';
import { useEntertainerFeedPostsQuery, useFetchNextPosts } from '@queries/PostQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
  useTopEntertainersOptimisticUpdate,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { CurrentCity, useCitiesQuery } from '@hooks/useCurrentUserLocation';
import { useShareLink } from '@hooks/useShareLink';

import { historyStack, useNavigate, useRouter, useStoredBackUrl } from '@router/index';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { LineChart } from '@components/charts/LineChart/LineChart';
import { Chip } from '@components/chips/Chip';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { SearchField } from '@components/form/SearchField/SearchField';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { VerifiedIcon } from '@components/icons/VerifiedIcon';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { BASIC_RATING_COLOR_RECORD } from '@components/overlays/CritiqueOverlay/CritiqueOverlayConstants';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { FeaturedPhotoManagementSection } from '../components/FeaturedPhotoManagementSection';
import { BoostProfileSection } from './BoostProfileSection';
import { ClapIcon } from './ClapIcon';
import { EntertainerCritiquePopup } from './EntertainerCritiquePopup';
import { FeaturedEntertainersSection } from './FeaturedEntertainersSection';
import { PastEventsBadgesSection } from './PastEventsBadgesSection';

const BOOST_ENABLED = false;

const getIntroMessage = ({
  cityName,
  entertainerTypeLabelPlural,
}: {
  cityName?: string;
  entertainerTypeLabelPlural: string;
}) => {
  return `Check out the hot takes for ${entertainerTypeLabelPlural.toLowerCase()} in ${cityName ?? 'your city'}. Scroll through our endless list of questions and support your favorites!`;
};

interface EntertainerRankingsContentProps {
  isBookingCtaVisible: boolean;
  isTrendingLooksCtaVisible?: boolean;
  city: {
    _id: string;
    slug?: string;
    name: string;
    areasOfImprovementEnabled?: boolean;
    features?: {
      areasOfImprovementEnabled?: boolean;
      ratingsLeaderboardEnabled?: boolean;
      boostProfileEnabled?: boolean;
    };
  };
  type: Entertainer['type'];
  back: string;
  entertainerFeedPosts: any[];
  entertainerFeedPostsAreReady?: boolean;
}

export const useEntertainerFeed = ({ cityId }: { cityId: string }) => {
  const { postsAreReady: entertainerFeedPostsAreReady, posts = [] } = useEntertainerFeedPostsQuery({
    cityId,
  });

  const [additionalPosts, setAdditionalPosts] = useState<any[]>([]);
  const fetchPosts = useFetchNextPosts();
  const fetchNextEntertainerFeedPosts = async () => {
    const { posts: newPosts = [] } = await fetchPosts('entertainers', cityId);
    setAdditionalPosts((prevPosts) => [...prevPosts, ...newPosts]);
  };

  const entertainerFeedPosts = useMemo(() => {
    return [...posts, ...additionalPosts];
  }, [posts, additionalPosts]);

  return {
    entertainerFeedPosts,
    entertainerFeedPostsAreReady,
    fetchNextEntertainerFeedPosts,
  };
};

const CityEntertainerRatingsContent: FC<EntertainerRankingsContentProps> = ({
  isBookingCtaVisible,
  isTrendingLooksCtaVisible = true,
  city: currentCity,
  type,
  back,
  entertainerFeedPosts,
  entertainerFeedPostsAreReady,
}) => {
  const { userIsLoggedIn } = useAppContext();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();
  const { track } = useAnalytics();
  const { entertainer: currentEntertainer, basicRatings: entertainerBasicRatings } =
    useCurrentEntertainerQuery();

  const entertainerTypeLabel = TYPES_RECORD[type];
  const entertainerTypeLabelPlural = pluralize(entertainerTypeLabel);
  const entertainerTypeLabelPluralCapitalized =
    type === 'dragPerformer' ? 'Drag Performers' : entertainerTypeLabelPlural;

  const handleEntertainerOnboard = async () => {
    track('Click entertainer onboarding');
    createAuthGateHandler(async () => {
      navigate(`/entertainer/onboarding`, {
        state: {
          back,
        },
      });
    });
  };

  const introMessage = getIntroMessage({
    entertainerTypeLabelPlural,
    cityName: currentCity?.name,
  });

  const { didCopy, shareLink } = useShareLink();
  const handleShareClick = async () => {
    track('Click share');
    await shareLink({
      url:
        currentCity.slug && type === 'dragPerformer'
          ? `https://dragratings.com/${currentCity.slug}`
          : `https://my.gaggl.app/noauth/entertainers/${type}/${currentCity._id}/rankings`,
    });
  };

  if (!entertainerFeedPostsAreReady) {
    return (
      <>
        <Skeleton height={24} />
        <Skeleton height={120} />
        <Skeleton height={24} />
        <Skeleton height={180} />
        <Skeleton height={180} />
        <Skeleton height={180} />
        <Skeleton height={180} />
        <Skeleton height={180} />
      </>
    );
  }

  const isFeatureLookLocked =
    userIsLoggedIn && currentEntertainer && !currentEntertainer.featuredMedia?.length;

  return (
    <>
      {currentCity?.features?.boostProfileEnabled &&
        currentEntertainer?.cityIds?.includes(currentCity?._id) && (
          <BoostProfileSection
            city={currentCity}
            currentEntertainer={currentEntertainer}
            entertainerBasicRatings={entertainerBasicRatings}
            type={type}
            back={back}
          />
        )}

      <TitleToolbar
        className="mb-0"
        text={
          currentCity ? (
            `${entertainerTypeLabelPluralCapitalized} in ${currentCity?.name}`
          ) : (
            <Skeleton height={25} />
          )
        }
        size="md"
        action={
          <Button
            variant="smooth"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={
              didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share-square" />
            }
            className="opacity-90"
            onClick={handleShareClick}
          >
            {didCopy ? 'Copied!' : 'Share'}
          </Button>
        }
      />
      {/* <TitleToolbar
          text={currentCity ? `Drag Performers` : <Skeleton height={25} />}
          className="title-toolbar-welcome"
        /> */}
      {/* <TitleToolbar text="Your Rankings 🏆" size="md" /> */}
      <p className="mt-1">{introMessage}</p>

      {!currentEntertainer && (
        <>
          <LinkBanner
            icon={
              <IconButton className="mx-auto" badge variant="smooth" color="neutral" size="lg">
                <FontAwesomeIcon name="envelope" />
              </IconButton>
            }
            title={`Are you a ${type === 'dragPerformer' ? 'drag artist' : entertainerTypeLabel.toLowerCase()}?`}
            description="Join and start collecting your compliments!"
            onClick={handleEntertainerOnboard}
          />
        </>
      )}

      {isBookingCtaVisible && (
        <LinkBanner
          icon={
            <IconButton className="mx-auto" variant="smooth" color="neutral" size="lg">
              <FontAwesomeIcon name="calendar" />
            </IconButton>
          }
          title="Looking for entertainers?"
          description={`Send gig opportunities to registered performers in ${currentCity?.name}`}
          onClick={() => navigate(`/noauth/open-bookings/${currentCity?._id}/create`)}
        />
      )}

      {userIsLoggedIn && <PastEventsBadgesSection cityId={currentCity._id} back={back} />}

      <FeaturedEntertainersSection cityId={currentCity._id} back={back} />

      {userIsLoggedIn && isTrendingLooksCtaVisible && (
        <Button
          className="mt-2"
          roundness="rounded"
          variant="smooth"
          color="neutral"
          onClick={() => {
            track('See Trending Looks Click');
            navigate('/community?topic=featuredLooks', {
              animate: false,
            });
          }}
        >
          View Trending Looks
        </Button>
      )}

      <TitleToolbar text="Hot Takes 🔥" size="md" />
      {!!entertainerFeedPosts?.length ? (
        <>
          {entertainerFeedPosts?.map((post, i) => <PostCard key={i} post={post} disableLazyLoad />)}
        </>
      ) : (
        <EmptyState
          title="No Performers Yet"
          text={`Be the first to showcase your talent in ${currentCity.name}! Join now and start connecting the local community.`}
          button={{
            text: 'Create Your Profile',
            onClick: handleEntertainerOnboard,
          }}
        />
      )}
    </>
  );
};

export const CityEntertainerRatingScreen = ({
  cityId,
  type,
  back: initialBack,
}: {
  cityId: string;
  type: Entertainer['type'];
  back?: string;
}) => {
  const { userIsLoggedIn } = useAppContext();
  const { storedBackUrl } = useStoredBackUrl();
  const { cities } = useCitiesQuery();
  const { track } = useAnalytics();
  const { back: navigateBack } = useRouter();

  const currentCity = useMemo<CurrentCity | undefined>(() => {
    if (!cities?.length || !cityId) {
      return null;
    }

    return cities.find((city) => city._id === cityId);
  }, [cities, cityId]);

  const { entertainerFeedPosts, entertainerFeedPostsAreReady, fetchNextEntertainerFeedPosts } =
    useEntertainerFeed({ cityId: currentCity?._id });

  const entertainerTypeLabel = TYPES_RECORD[type];
  const entertainerTypeLabelPlural = pluralize(entertainerTypeLabel);

  const metaTitle = `${currentCity?.name ?? 'Local'} Drag Leaderboard | Gaggl`;
  const introMessage = getIntroMessage({
    entertainerTypeLabelPlural,
    cityName: currentCity?.name,
  });

  const back = initialBack ?? storedBackUrl;

  return (
    <Screen
      name="Entertainer Rankings Screen"
      hideToolbar
      disableLocationPermissionGate
      headerProps={{
        title: <Screen.GagglTitle />,
        left:
          userIsLoggedIn && !back ? (
            <Button
              startIcon={<FontAwesomeIcon name="chevron-left" />}
              variant="smooth"
              color="neutral"
              roundness="rounded"
              className="py-2"
              onClick={() => {
                track('Click Live Feed Left');
                navigateBack('/home');
              }}
            >
              Feed
            </Button>
          ) : undefined,
        back: back ? { to: back } : undefined,
        right: (
          <Button
            variant="outlined"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={<FontAwesomeIcon className="fs-6" faStyle="fab" name="instagram" />}
            className="opacity-90"
            onClick={() => {
              track('Click follow on Instagram');
              openExternalUrl('https://www.instagram.com/gaggl.app/');
            }}
          >
            gaggl.app
          </Button>
        ),
      }}
      metaData={{
        title: metaTitle,
        description: introMessage,
        image: 'https://my.gaggl.app/assets/images/drag-leaderboard.png',
      }}
    >
      <Screen.Content
        maxWidth={425}
        infiniteScroll={{
          hasReachedEnd: currentCity
            ? entertainerFeedPostsAreReady && !entertainerFeedPosts?.length
            : true,
          onLoadMore: fetchNextEntertainerFeedPosts,
        }}
      >
        {currentCity && (
          <CityEntertainerRatingsContent
            entertainerFeedPostsAreReady={entertainerFeedPostsAreReady}
            entertainerFeedPosts={entertainerFeedPosts}
            isBookingCtaVisible
            isTrendingLooksCtaVisible
            city={currentCity}
            type={type}
            back={`/noauth/entertainers/${type}/${cityId}/rankings`}
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
