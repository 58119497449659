import { FC, MouseEventHandler } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import * as dayjs from 'dayjs';
import { truncate } from 'lodash';

import { useEvents } from '@queries/EventQueries';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Screen } from '@components/layout/Screen/Screen';
import { RenderTable } from '@components/table/RenderTable';

import './SuperAdminManageUpcomingEventsScreen.scss';

export const SuperAdminManageUpcomingEventsScreen: FC<{}> = () => {
  const { currentCity } = useCurrentUserCity();
  const { eventsAreReady, events = [] } = useEvents({
    cityId: currentCity?._id,
    startDate: dayjs().format('YYYY-MM-DD'),
    includeInactive: 'true',
  });
  const navigate = useNavigate();

  const createNavigateToCleanupHandler =
    (eventId: string): MouseEventHandler =>
    (mouseEvent) => {
      mouseEvent.stopPropagation();
      navigate(`/events/${eventId}/cleanup`, { state: { back: '/super-admin/manage/events' } });
    };

  const headCells = [
    {
      id: 'status',
      label: '',
      TableBodyCell: ({ row }) => (
        <td>
          <FontAwesomeIcon
            name="circle"
            className={row.isActive ? 'event-is-active' : 'event-is-inactive'}
          />
        </td>
      ),
    },
    { id: 'title', label: 'Title', TableBodyCell: ({ row }) => <td>{row.title}</td> },
    {
      id: 'action',
      label: 'Action',
      TableBodyCell: ({ row }) => (
        <td>
          <Button onClick={createNavigateToCleanupHandler(row._id)}>
            <FontAwesomeIcon name="edit" />
          </Button>
        </td>
      ),
    },
  ];
  const rows = events.map((event) => ({
    key: event._id,
    ...event,
  }));

  return (
    <Screen
      name="SuperAdmin Manage Upcoming Events Screen"
      headerProps={{ back: { to: '/profile' }, title: 'Manage Events' }}
    >
      <Screen.Content maxWidth={425}>
        {eventsAreReady ? (
          <div>
            <RenderTable headCells={headCells} rows={rows} />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
