import { FC, useEffect } from 'react';

import { useVenueAndPromoterRankings } from '@queries/CritiqueQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { RankingList } from '@screens/entertainer/EntertainerRankingsScreen/EntertainerRankingsScreen';

export const PromoterRankingsContent: FC<{ cityId: string }> = ({ cityId }) => {
  const { currentCity } = useCurrentUserCity();
  const {
    promoterRankings = [],
    venueAndPromoterRankingsAreReady,
    refetchVenueAndPromoterRankings,
  } = useVenueAndPromoterRankings(cityId);
  const navigate = useNavigate();
  const { track } = useAnalytics();

  useEffect(() => {
    refetchVenueAndPromoterRankings();
  }, []);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleClickRating =
    (promoterId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Click rate promoter', {
        ...payload,
        promoterId,
      });
      await critiqueDocumentAsync({
        ...payload,
        documentType: 'promoters',
        documentId: promoterId,
      });
    };

  const introMessage = `Discover local ${currentCity?.name} organizations hosting LGBTQ+ events! Browse their profiles and rank them to support their commitment to diversity, inclusion, and good vibes.`;

  return (
    <>
      <TitleToolbar
        className="mb-0"
        text={currentCity ? `LGBTQ Promoters` : <Skeleton height={25} />}
        size="md"
      />
      <p className="mt-1">{introMessage}</p>
      <TitleToolbar
        text="Leaderboard"
        size="sm"
        action={
          venueAndPromoterRankingsAreReady ? (
            <span>{`${promoterRankings.length} Total`}</span>
          ) : (
            <Skeleton height={16} />
          )
        }
      />
      {!venueAndPromoterRankingsAreReady || promoterRankings.length ? (
        <RankingList
          isLoading={!venueAndPromoterRankingsAreReady}
          list={promoterRankings}
          onClick={(promoter) => {
            navigate(`/promoters/${promoter._id}`, {
              state: {
                back: '/community/promoters',
              },
            });
          }}
          onClickRating={handleClickRating}
        />
      ) : (
        <EmptyState
          title="No Promoters Yet"
          text={`Be the first to share your events in ${currentCity.name}! Join now and start connecting the local queer community.`}
          button={{
            text: 'Create Your Profile',
            onClick: () => {
              track('View create promoter profile', {
                source: 'community empty state',
              });
              navigate('/manage/promoter', {
                state: { back: '/profile' },
              });
            },
          }}
        />
      )}
    </>
  );
};
