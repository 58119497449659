import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import { useCurrentUserGagglRankQuery } from '@queries/CritiqueQueries';
import {
  useCityEntertainerAvailabilityQuery,
  useCurrentEntertainerBadgesQuery,
  useCurrentEntertainerQuery,
  useCurrentUserEntertainerCritiqueQuery,
  useEntertainerReviewsQuery,
} from '@queries/EntertainerQueries';

export interface CreateEntertainerEventMutationPayload {
  eventImage: any;
  title: string;
  description: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  cityId: string;
  isRecurring: boolean;
  entertainerIds: string[];
  tagIds: string[];
  recurringSchedule?: {
    type: 'weekly' | 'bi-weekly' | 'monthlyNthDay';
  };
  venue: {
    _id?: string;
    title: string;
  };
}

export const useCreateEntertainerEventMutation = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: CreateEntertainerEventMutationPayload) => {
      return API.post<{}, CreateEntertainerEventMutationPayload>(
        `/v1/user/entertainer/${entertainerId}/events`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    createEntertainerEventAsync: mutateAsync,
  };
};

interface AddEntertainerToEventMutationPayload {
  eventId: string;
}
export const useAddEntertainerToEventMutation = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: AddEntertainerToEventMutationPayload) => {
      return API.post<{}, AddEntertainerToEventMutationPayload>(
        `/v1/user/entertainer/${entertainerId}/add-to-event`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    addEntertainerToEventAsync: mutateAsync,
  };
};

interface RespondToEntertainerEventRequestMutationPayload {
  eventId: string;
  status: 'accepted' | 'rejected';
}
export const useRespondToEntertainerEventRequestMutation = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: RespondToEntertainerEventRequestMutationPayload) => {
      return API.post<{}, RespondToEntertainerEventRequestMutationPayload>(
        `/v1/user/entertainer/${entertainerId}/respond-to-event`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    respondToEntertainerEventRequestAsync: mutateAsync,
  };
};

export type UpdateEntertainerEventMutationPayload = Partial<CreateEntertainerEventMutationPayload>;

export const useUpdateEntertainerEventMutation = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: ({
      eventId,
      ...payload
    }: UpdateEntertainerEventMutationPayload & { eventId: string }) => {
      return API.put<{}, UpdateEntertainerEventMutationPayload>(
        `/v1/user/entertainer/${entertainerId}/events/${eventId}`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    updateEntertainerEventAsync: mutateAsync,
  };
};

export const useDeleteEntertainerEventMutation = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: ({ eventId }: { eventId: string }) => {
      return API.delete<{}, {}>(`/v1/user/entertainer/${entertainerId}/events/${eventId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    deleteEntertainerEventAsync: mutateAsync,
  };
};

export interface RespondToBookingRequestMutationPayload {
  status: 'interested' | 'notInterested';
}

export const useRespondToBookingRequestMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: ({
      bookingRequestId,
      ...payload
    }: RespondToBookingRequestMutationPayload & { bookingRequestId: string }) => {
      return API.post<{}, RespondToBookingRequestMutationPayload>(
        `/v1/entertainer/booking-request/${bookingRequestId}/respond`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    respondToBookingRequestAsync: mutateAsync,
  };
};

export interface RespondToBookingOpportunityMutationPayload {
  status: 'interested' | 'notInterested';
}

export const useRespondToBookingOpportunityMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: useRespondToBookingOpportunityMutation.mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    respondToBookingOpportunityAsync: mutateAsync,
  };
};
useRespondToBookingOpportunityMutation.mutationFn = ({
  bookingOpportunityId,
  ...payload
}: RespondToBookingOpportunityMutationPayload & { bookingOpportunityId: string }) => {
  return API.post<{}, RespondToBookingRequestMutationPayload>(
    `/v1/entertainer/booking-opportunity/${bookingOpportunityId}/respond`,
    payload,
  );
};

interface CreateEntertainerReviewPayload {
  text: string;
}
export const useCreateEntertainerReview = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: CreateEntertainerReviewPayload) => {
      return API.post(`/v1/entertainers/${entertainerId}/reviews/`, payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useEntertainerReviewsQuery.queryKey(entertainerId),
      });
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    createEntertainerReviewAsync: mutateAsync,
  };
};

interface SendEntertainerBadgePayload {
  lockedBadgeId?: string;
  eventId?: string;
  entertainerMediaId?: string;
  badgeId?: string;
  reason: string;
  meta?: any;
}
export const useSendEntertainerBadge = (entertainerId: string) => {
  const { entertainer: currentUserEntertainer } = useCurrentEntertainerQuery();
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: SendEntertainerBadgePayload) => {
      return API.post(`/v1/entertainers/${entertainerId}/badges`, payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentUserEntertainerCritiqueQuery.queryKey(entertainerId),
      });
      if (currentUserEntertainer?._id) {
        queryClient.invalidateQueries({
          queryKey: useCurrentEntertainerBadgesQuery.queryKey(currentUserEntertainer?._id),
        });
      }
    },
  });

  return {
    isSubmitting: isPending,
    sendEntertainerBadgeAsync: mutateAsync,
  };
};

interface AddEntertainerMediaPayload {
  title: string;
  description?: string;
  data: any;
}

export const useAddEntertainerMediaMutation = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: AddEntertainerMediaPayload) => {
      return API.post<{}, AddEntertainerMediaPayload>(
        `/v1/user/entertainer/${entertainerId}/media`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    addEntertainerMediaAsync: mutateAsync,
  };
};

interface UpdateEntertainerAvailabilityPayload {
  cityIds?: string[];
  availability: Record<string, boolean>;
  hasFlexibleAvailability?: boolean;
  referredBy?: string;
}

export const useUpdateEntertainerAvailabilityMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: UpdateEntertainerAvailabilityPayload) => {
      return API.post<{}, UpdateEntertainerAvailabilityPayload>(
        `/v1/user/entertainer/availability`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['city-entertainer-availability'],
        exact: false,
      });
    },
  });

  return {
    isSubmitting,
    updateEntertainerAvailabilityAsync: mutateAsync,
  };
};

export const useEntertainerJoinSeriesMutation = () => {
  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: ({ seriesId }: { seriesId: string }) => {
      return API.post(`/v1/series/${seriesId}/entertainers`, {});
    },
  });

  return {
    isSubmitting,
    entertainerJoinSeriesAsync: mutateAsync,
  };
};
