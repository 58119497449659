import { FC, useState } from 'react';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { EntertainerBookingRequest } from '@queries/EntertainerQueries';

import { useRespondToBookingRequestMutation } from '@mutations/EntertainerMutations';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { OptionButton } from '@components/buttons/OptionButton';
import Card from '@components/layout/Card/Card';

interface BookingRequestCardProps {
  bookingRequest: EntertainerBookingRequest;
}

export const SentBookingRequestCard: FC<BookingRequestCardProps> = ({ bookingRequest }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Card.Header
        title={bookingRequest?.entertainer?.title}
        subtitle={`Sent on ${dayjs(bookingRequest.createdAt).format('MMM D, YYYY')}`}
        action={
          <Button variant="outlined" color="neutral" roundness="rounded">
            {capitalize(bookingRequest.status)}
          </Button>
        }
      />
      <Card.Footer>
        <Button
          color="neutral"
          variant="smooth"
          roundness="rounded"
          onClick={() => {
            navigate(`/manage/talent/booking-request/${bookingRequest._id}`);
          }}
        >
          Details
        </Button>
      </Card.Footer>
    </Card>
  );
};
