import { uuid } from '@utilities/uuid';

import { handleResponse } from './handleResponse';

const { REACT_APP_API_URL: apiUrl = '' } = process.env;

const getUnauthenicatedUserId = () => {
  const storedValue = localStorage.getItem('Unauthenticated-UserId');

  if (storedValue) {
    return storedValue;
  }

  const value = uuid();
  localStorage.setItem('Unauthenticated-UserId', value);
  return value;
};

const getHeaders = async () => {
  const defaultHeaders: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'API-Version': '1.1',
    'Unauthenticated-UserId': getUnauthenicatedUserId(),
  };

  const token = await localStorage.getItem('token');

  if (token) {
    defaultHeaders['Authorization'] = `Bearer ${token}`;
  }

  return defaultHeaders;
};

const API = {
  get: async <ResponseBody>(url: string): Promise<ResponseBody> => {
    const headers = await getHeaders();

    const response = await fetch(`${apiUrl}${url}`, {
      headers,
    });

    return handleResponse(response);
  },
  post: async <ResponseBody extends {}, RequestBody extends {}>(
    url: string,
    payload: RequestBody,
  ): Promise<ResponseBody> => {
    const headers = await getHeaders();
    const body = JSON.stringify(payload);

    const response = await fetch(`${apiUrl}${url}`, {
      method: 'POST',
      headers,
      body,
    });

    return handleResponse(response);
  },
  put: async <ResponseBody extends {}, RequestBody extends {}>(
    url: string,
    payload: RequestBody,
  ): Promise<ResponseBody> => {
    const headers = await getHeaders();
    const body = JSON.stringify(payload);

    const response = await fetch(`${apiUrl}${url}`, {
      method: 'PUT',
      headers,
      body,
    });

    return handleResponse(response);
  },

  delete: async <ResponseBody extends {}, RequestBody extends {}>(
    url: string,
    payload?: RequestBody,
  ): Promise<ResponseBody> => {
    const headers = await getHeaders();
    const body = JSON.stringify(payload);

    const response = await fetch(`${apiUrl}${url}`, {
      method: 'DELETE',
      headers,
      body,
    });

    return handleResponse(response);
  },
};

export default API;
