import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCitiesQuery, useCurrentUserLocation } from '@hooks/useCurrentUserLocation';
import { useDevice } from '@hooks/useDevice';

import Button from '@components/buttons/Button';
import { List } from '@components/layout/List/List';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

const IOSInstructions = () => (
  <>
    <p className="m-0">
      <strong>iOS Steps:</strong>
    </p>
    <ol className="mt-1">
      <li className="mb-1">{`Go to Settings > Privacy & Security > Location Services.`}</li>
      <li className="mb-1">Make sure that Location Services is on.</li>
      <li className="mb-1">Scroll down to find "Gaggl".</li>
      <li className="mb-1">Tap "Gaggl" and select "While Using the App" or "Always".</li>
    </ol>
  </>
);

const BrowserInstructions = () => (
  <>
    <p className="m-0">
      <strong>iOS Steps:</strong>
    </p>
    <ol className="mt-1">
      <li className="mb-1">{`Go to Settings > Privacy & Security > Location Services.`}</li>
      <li className="mb-1">Make sure that Location Services is on.</li>
      <li className="mb-1">Scroll down to find your browser.</li>
      <li className="mb-1">Tap "Gaggl" and select "While Using the App" or "Always".</li>
    </ol>
  </>
);

const CITY_PICKER_ENABLED = true;

export const CitiesPopoverWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { overrideCurrentCity } = useAppContext();
  const { cities } = useCitiesQuery();

  return (
    <PopoverWrapper
      PopoverContent={({ onClose }) => (
        <div style={{ height: 200, overflow: 'auto' }}>
          {cities?.length && (
            <List
              listItems={
                cities
                  ?.filter(({ eventsAreEnabled }) => eventsAreEnabled)
                  ?.map((city) => ({
                    title: city.name,
                    onClick: () => {
                      overrideCurrentCity(city, {
                        storeInLocalStorage: true,
                      });
                      onClose();
                    },
                  })) ?? []
              }
            />
          )}
        </div>
      )}
    >
      {children}
    </PopoverWrapper>
  );
};

export const LocationPermissionGateUnwrapped = () => {
  const { userIsLoggedIn, currentCityOverride } = useAppContext();
  const { isNativePlatform } = useDevice();
  const { checkCurrentLocationPermissions, refetchCurrentLocation } = useCurrentUserLocation();
  const [isFetched, setIsFetched] = useState(false);
  const [hasLocationPermission, setHasLocationPermission] = useState(false);
  const [showError, setShowError] = useState(false);
  const { track } = useAnalytics();
  const { cities } = useCitiesQuery();

  useEffect(() => {
    if (userIsLoggedIn) {
      checkCurrentLocationPermissions().then((hasPermission) => {
        setHasLocationPermission(hasPermission);
        setIsFetched(true);
      });
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 6000);
    }
  }, [showError]);

  const isVisible =
    userIsLoggedIn &&
    isFetched &&
    !hasLocationPermission &&
    (isNativePlatform() ? true : !currentCityOverride);
  useEffect(() => {
    if (isVisible) {
      track('Location Permission Gate Shown');
    }
  }, [isVisible]);

  const LocationServicesContent = (
    <Popup.Content>
      <TitleToolbar text="📍 Enable location services" size="md" />
      <p className="mt-2 mb-4">
        For the best experience, enable location services to find the best queer events near you.
      </p>
      {isNativePlatform() ? <IOSInstructions /> : <BrowserInstructions />}
      <Button
        disabled={false}
        onClick={async () => {
          setShowError(false);
          const hasPermission = await checkCurrentLocationPermissions();
          setHasLocationPermission(hasPermission);

          if (hasPermission) {
            refetchCurrentLocation();
            track('Check Location Permissions', {
              Status: 'Granted',
            });
          } else {
            setShowError(true);
            track('Check Location Permissions', {
              Status: 'Not Granted',
            });
          }
        }}
      >
        Check Again
      </Button>
      {!isNativePlatform() && cities?.length && CITY_PICKER_ENABLED && (
        <CitiesPopoverWrapper>
          <Button className="mt-2" variant="flat" color="neutral">
            Choose City
          </Button>
        </CitiesPopoverWrapper>
      )}
    </Popup.Content>
  );

  return (
    <Popup height="full" isVisible={isVisible} disableClose onClose={() => {}}>
      {isNativePlatform() ? (
        LocationServicesContent
      ) : (
        <Popup.Content>
          <TitleToolbar text="📍 Select a City" size="md" />
          <p className="mt-2 mb-4">
            To get started, select your city to explore what Gaggl has to offer!
          </p>
          {cities?.length && (
            <CitiesPopoverWrapper>
              <Button className="mt-2" variant="outlined" color="neutral">
                Choose City
              </Button>
            </CitiesPopoverWrapper>
          )}
        </Popup.Content>
      )}
    </Popup>
  );
};

const LOCATION_PERMISSION_GATE_ENABLED = true;
export const LocationPermissionGate = () => {
  const { isLocationPermissionGateDisabled } = useAppContext();

  if (!LOCATION_PERMISSION_GATE_ENABLED || isLocationPermissionGateDisabled) {
    return <></>;
  }

  return <LocationPermissionGateUnwrapped />;
};
