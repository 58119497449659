import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import { useAppContext } from '../contexts/AppContext';
import { CritiqueData, UserCritique } from './CritiqueQueries';
import { FeedEvent } from './EventQueries';

export interface CurrentPromoter {
  _id: string;
  title: string;
  shortDescription?: string;
  description?: string;
  hasEventsEnabled: boolean;
  cityIds: string[];
  permissions: {
    status: 'pending' | 'approved' | 'rejected';
  };
  contact: {
    instagram?: string;
  };
}

export const useCurrentPromoterQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const {
    isFetched: promoterIsReady,
    data,
    refetch: refetchPromoter,
  } = useQuery({
    queryKey: useCurrentPromoterQuery.queryKey(),
    queryFn: () => useCurrentPromoterQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    promoterIsReady,
    promoter: data?.promoter,
    refetchPromoter,
  };
};
useCurrentPromoterQuery.queryKey = () => ['user', 'promoter'];
useCurrentPromoterQuery.queryFn = () => API.get<{ promoter: CurrentPromoter }>(`/v1/promoter/me`);

export const fetchCurrentPromoter = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: useCurrentPromoterQuery.queryKey() });

  return queryClient.fetchQuery({
    queryKey: useCurrentPromoterQuery.queryKey(),
    queryFn: () => useCurrentPromoterQuery.queryFn(),
  });
};

export const useFetchCurrentPromoter = () => {
  const queryClient = useQueryClient();

  return () => fetchCurrentPromoter(queryClient);
};

export const BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES = [
  'interested',
  'notInterested',
  'confirmed',
  'rejected',
] as const;
export type BookingOpportunityEntertainerStatus =
  (typeof BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES)[number];

export interface BookingOpportunityEntertainer {
  entertainer: {
    _id: string;
    title: string;
    tagline: string;
    description: string;
    details: any;
    avatar?: string;
    bookingHandle?: string;
    contact: {
      email: string;
      facebook: string;
      instagram: string;
    };
  };
  status: BookingOpportunityEntertainerStatus;
}

export interface AskedDetailQuestion {
  _id: string;
  detailQuestionId: string;
  question: string;
  answer?: string;
}
export interface PublicBookingOpportunity {
  _id: string;
  isActive: boolean;
  createdBy: string;
  status: string;
  title: string;
  startDate: string;
  startTime: string;
  details: string;
  eventId?: string;
  primaryImage?: string;
  city: {
    _id: string;
    name: string;
  };
  venue: {
    _id: string;
    title: string;
  };
  promoter: { _id: string; title: string };
  entertainers: BookingOpportunityEntertainer[];
  updates: {
    user: {
      _id: string;
      displayName: string;
    };
    message: string;
    createdAt: string;
  }[];
  askedDetailQuestions: AskedDetailQuestion[];
}
interface PromoterManageResponseBody {
  events: FeedEvent[];
  bookingOpportunities: PublicBookingOpportunity[];
  instagramPosts: any[];
}

export const usePromoterManageQuery = (promoterId: string | undefined) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: usePromoterManageQuery.queryKey(promoterId),
    queryFn: () => API.get<PromoterManageResponseBody>(`/v1/user/promoter/${promoterId}`),
    enabled: !!promoterId,
  });

  return {
    promoterDetailsAreReady: isFetched,
    promoterDetails: data,
    refetchPromoterDetails: refetch,
  };
};
usePromoterManageQuery.queryKey = (promoterId: string | undefined) => [
  'user',
  'promoter',
  promoterId,
];

export interface Promoter {
  _id: string;
  title: string;
  description?: string;
  followerCount: number;
  upcomingEventsCount: number;
  isClaimed?: boolean;
}

export const useSuggestedPromotersQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useSuggestedPromotersQuery.queryKey(),
    queryFn: () => useSuggestedPromotersQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    promotersAreReady: isFetched,
    promoters: data?.promoters,
    refetchPromoters: refetch,
  };
};
useSuggestedPromotersQuery.queryKey = () => ['suggested-promoters'];
useSuggestedPromotersQuery.queryFn = () =>
  API.get<{ promoters: Promoter[] }>(`/v1/promoters/suggested`);

export const usePromoterQuery = (promoterId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: usePromoterQuery.queryKey(promoterId),
    queryFn: () => usePromoterQuery.queryFn(promoterId),
    enabled: !!promoterId,
  });

  return {
    promoterIsReady: isFetched,
    promoter: data?.promoter,
    promoterEvents: data?.events,
    refetchPromoter: refetch,
  };
};
usePromoterQuery.queryKey = (promoterId: string) => ['promoters', promoterId];
usePromoterQuery.queryFn = (promoterId: string) =>
  API.get<{
    promoter: Omit<Promoter, 'upcomingEventsCount'>;
    events: { upcoming: FeedEvent[]; previous: FeedEvent[] };
  }>(`/v1/promoters/${promoterId}`);

export const usePromoterCritiqueDataQuery = (promoterId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: usePromoterCritiqueDataQuery.queryKey(promoterId),
    queryFn: () => usePromoterCritiqueDataQuery.queryFn(promoterId),
    enabled: isEnabled && !!promoterId,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    promoterCritiqueDataIsReady: isFetched,
    promoterCritiqueData: data?.critiqueData,
    refetchPromoterCritiqueData: refetch,
  };
};
usePromoterCritiqueDataQuery.queryKey = (promoterId) => ['promoter-critiques', promoterId];
usePromoterCritiqueDataQuery.queryFn = (promoterId) =>
  API.get<{ critiqueData: CritiqueData }>(`/v1/promoters/${promoterId}/critiques`);

export const useCurrentUserPromoterCritiqueQuery = (promoterId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserPromoterCritiqueQuery.queryKey(promoterId),
    queryFn: () => useCurrentUserPromoterCritiqueQuery.queryFn(promoterId),
    enabled: isEnabled && !!promoterId,
  });

  return {
    userPromoterCritiqueIsReady: isFetched,
    userPromoterCritique: data?.userCritique,
    refetchUserPromoterCritique: refetch,
  };
};
useCurrentUserPromoterCritiqueQuery.queryKey = (promoterId) => [
  'user-critiques',
  'promoters',
  promoterId,
];
useCurrentUserPromoterCritiqueQuery.queryFn = (promoterId) =>
  API.get<{ userCritique: UserCritique }>(`/v1/user/critiques/promoters/${promoterId}`);

export const useBookingOpportunityQuery = (bookingOpportunityId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useBookingOpportunityQuery.queryKey(bookingOpportunityId),
    queryFn: () => useBookingOpportunityQuery.queryFn(bookingOpportunityId),
    enabled: !!bookingOpportunityId,
  });

  return {
    bookingOpportunityIsReady: isFetched,
    bookingOpportunity: data?.bookingOpportunity,
    refetchBookingOpportunity: refetch,
  };
};
useBookingOpportunityQuery.queryKey = (bookingOpportunityId: string) => [
  'booking-opportunities',
  bookingOpportunityId,
];
useBookingOpportunityQuery.queryFn = (bookingOpportunityId: string) =>
  API.get<{ bookingOpportunity: PublicBookingOpportunity }>(
    `/v1/booking-opportunities/${bookingOpportunityId}`,
  );

export const useCityBookingOpportunitiesQuery = (cityId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCityBookingOpportunitiesQuery.queryKey(cityId),
    queryFn: () => useCityBookingOpportunitiesQuery.queryFn(cityId),
    enabled: !!cityId,
  });

  return {
    bookingOpportunitiesAreReady: isFetched,
    bookingOpportunities: data?.bookingOpportunities,
    refetchBookingOpportunities: refetch,
  };
};
useCityBookingOpportunitiesQuery.queryKey = (cityId: string) => [
  'city',
  cityId,
  'booking-opportunities',
];
useCityBookingOpportunitiesQuery.queryFn = (cityId: string) =>
  API.get<{ bookingOpportunities: PublicBookingOpportunity[] }>(
    `/v1/cities/${cityId}/booking-opportunities`,
  );
