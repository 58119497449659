import { Fab } from 'framework7-react';
import { FC, memo, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import * as dayjs from 'dayjs';
import { capitalize, isEmpty } from 'lodash';
import pluralize from 'pluralize';
import { RANKINGS_NAVIGATION_ENABLED } from 'src/constants/FeatureFlags';

import {
  Entertainer,
  EntertainerBookingOpportunity,
  EntertainerCompetition,
  TYPES_RECORD,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';
import { useUserConversationsQuery } from '@queries/UserQueries';

import {
  useCreateEntertainerEventMutation,
  useDeleteEntertainerEventMutation,
} from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';
import { useShareLink } from '@hooks/useShareLink';

import { useNavigate, useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import EventCard, { EventHeader, EventImage } from '@components/cards/EventCard';
import { Chip } from '@components/chips/Chip';
import { FormLabel } from '@components/form/FormLabel';
import { ImageUploadField } from '@components/form/ImageUploadField';
import { Input } from '@components/form/Input';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { BadgeSection } from '@screens/CommunityScreen/BadgeSection';

import { GagglLogo } from '../../assets/GagglLogo';
import { sanitizeInstagramUsername } from '../../utilities/sanitizeInstagramUsername';
import { BookingOpportunityCard } from './BookingOpportunityCard';
import { BookingRequestCard } from './BookingRequestCard';
import {
  CreateEntertainerProfileScreen,
  useUpdateEntertainerMutation,
} from './CreateEntertainerProfileScreen';
import { OnboardEntertainerScreen } from './OnboardEntertainerScreen/OnboardEntertainerScreen';
import { ContactFields } from './components/ContactFields';
import { FeaturedPhotoManagementSection } from './components/FeaturedPhotoManagementSection';

const BookingOpportunitiesContent: FC<{
  entertainer: Entertainer;
  bookingOpportunities: EntertainerBookingOpportunity[];
}> = ({ entertainer, bookingOpportunities }) => {
  const { isSubmitting, updateEntertainerAsync } = useUpdateEntertainerMutation();
  const [contact, setContact] = useState<Entertainer['contact']>(entertainer?.contact ?? {});

  const hasEntertainerSetupContactInfo = !isEmpty(entertainer?.contact) || true;
  if (!bookingOpportunities?.length) {
    return (
      <EmptyState
        title="No Gig Opportunities"
        text="No organizations have posted that they are looking for entertainers. Check back later."
        // Add "refer a producer" button
      />
    );
  }

  if (hasEntertainerSetupContactInfo) {
    return (
      <HorizontalScroll
        items={bookingOpportunities.map((bookingOpportunity) => (
          <BookingOpportunityCard
            key={bookingOpportunity._id}
            bookingOpportunity={bookingOpportunity}
          />
        ))}
      />
    );
  }

  return (
    <EmptyState
      title={`${bookingOpportunities.length} Gig ${pluralize('Opportunity', bookingOpportunities.length)}`}
      text={`Add your contact information to apply for ${pluralize('this', bookingOpportunities.length)} ${pluralize('gig', bookingOpportunities.length)}`}
      button={{
        text: 'Update',
        PopoverContent: ({ onClose }) => {
          return (
            <>
              <div className="px-4 py-3 grid gap-2">
                <ContactFields.Instagram
                  variant="outlined"
                  contact={contact}
                  setContact={setContact}
                />
                <ContactFields.Facebook
                  variant="outlined"
                  contact={contact}
                  setContact={setContact}
                />
                <ContactFields.Email variant="outlined" contact={contact} setContact={setContact} />
                <div className="mt-2 d-grid grid-cols-2 gap-2">
                  <Button
                    disabled={isSubmitting}
                    roundness="rounded"
                    onClick={async () => {
                      await updateEntertainerAsync({ contact });
                      onClose();
                    }}
                  >
                    Save
                  </Button>
                  <Button roundness="rounded" variant="outlined" color="neutral" onClick={onClose}>
                    Close
                  </Button>
                </div>
              </div>
            </>
          );
        },
      }}
    />
  );
};

const BookingHandleButton = ({ entertainer }) => {
  const [didCopy, setDidCopy] = useState(false);
  const { updateEntertainerAsync } = useUpdateEntertainerMutation();
  const { track } = useAnalytics();

  const bookingHandle = getDefaultBookingHangle(entertainer);
  const bookingLink = `https://book.gaggl.app/${bookingHandle}`;

  const handleCopyLink = async () => {
    track('Copied Booking Link', { bookingHandle, bookingLink });

    if (bookingHandle !== entertainer.bookingHandle) {
      updateEntertainerAsync({ bookingHandle }).then(() => console.log('bookingHandle updated'));
    }
    await navigator.clipboard.writeText(bookingLink);

    setDidCopy(true);

    setTimeout(() => {
      setDidCopy(false);
    }, 5000);
  };

  return (
    <Button
      startIcon={<FontAwesomeIcon name="copy" />}
      roundness="rounded"
      variant="outlined"
      color="neutral"
      size="sm"
      onClick={handleCopyLink}
    >
      {didCopy ? 'Copied!' : 'Booking Link'}
    </Button>
  );
};

export const AvailabilityReferralInput: FC<{ availabilityReferralUrl: string }> = ({
  availabilityReferralUrl,
}) => {
  const { track } = useAnalytics();
  const { didCopy, shareLink } = useShareLink();
  const handleCopyLink = async () => {
    track('Copied Booking Link', { availabilityReferralUrl });

    await shareLink({ url: availabilityReferralUrl });
  };

  return (
    <div>
      <div
        className="d-flex align-items-center ps-3"
        style={{
          border: '1px solid rgba(255,255,255,.5)',
          borderRadius: '.5rem',
          background: 'rgba(255,255,255,.1)',
        }}
      >
        <span>{availabilityReferralUrl}</span>
        <div className="ml-auto">
          <Button
            startIcon={
              didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share" />
            }
            roundness="rounded"
            variant="flat"
            color="neutral"
            size="sm"
            onClick={handleCopyLink}
          >
            {didCopy ? 'Copied!' : 'Share referral link'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const BookingHandleInput = ({ entertainer }) => {
  const [bookingHandle, setBookingHandle] = useState(getDefaultBookingHangle(entertainer));
  const { updateEntertainerAsync } = useUpdateEntertainerMutation();
  const { track } = useAnalytics();

  const bookingLinkRoot = 'werqwith.me/';
  const bookingLink = `${bookingLinkRoot}${bookingHandle}`;
  const { didCopy, shareLink } = useShareLink();

  const [inputIsFocused, setInputIsFocused] = useState(false);

  const handleCopyLink = async () => {
    track('Copied Booking Link', { bookingHandle, bookingLink });

    if (bookingHandle !== entertainer.bookingHandle) {
      updateEntertainerAsync({ bookingHandle }).then(() => console.log('bookingHandle updated'));
    }

    await shareLink({ url: `https://${bookingLink}` });
  };

  return (
    <div>
      <div
        className="d-flex align-items-center ps-3"
        style={{
          border: '1px solid rgba(255,255,255,.5)',
          borderRadius: '.5rem',
          background: 'rgba(255,255,255,.1)',
        }}
      >
        <span>{bookingLinkRoot}</span>
        <Input
          className="ps-0 py-1"
          wrapperClassName="flex-grow-1"
          defaultValue={bookingHandle}
          onChange={(e) => setBookingHandle(e.target.value)}
          style={{ height: 24, fontSize: 14, background: 'transparent' }}
          onFocus={() => setInputIsFocused(true)}
          onBlur={() => setInputIsFocused(false)}
        />
        <div>
          <Button
            startIcon={
              didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share" />
            }
            roundness="rounded"
            variant="flat"
            color="neutral"
            size="sm"
            onClick={handleCopyLink}
          >
            {didCopy ? 'Copied!' : 'Share'}
          </Button>
        </div>
      </div>
    </div>
  );
};

const getDefaultBookingHangle = (entertainer: Entertainer) => {
  if (entertainer.bookingHandle) {
    return entertainer.bookingHandle;
  }

  return entertainer.title
    .toLowerCase()
    .replace(/\s/g, '.')
    .replace(/[^a-z0-9.]/g, '');
};

const VenmoCard = ({ entertainer }) => {
  const { track } = useAnalytics();
  const [venmoHandle, setVenmoHandle] = useState(entertainer.contact?.venmo);
  const { isSubmitting, updateEntertainerAsync } = useUpdateEntertainerMutation();

  if (entertainer.contact?.venmo) {
    return <></>;
  }

  return (
    <Card className="mt-2">
      <Card.Body>
        <p className="mb-1">
          <strong>Add Venmo</strong>
        </p>
        <p>To accept tips on your "werqwith.me" page, add your Venmo handle to your profile.</p>
        <Input
          variant="outlined"
          placeholder="eg. your.venmo.handle"
          onChange={(e) => setVenmoHandle(e.target.value)}
        />
      </Card.Body>
      <Card.Footer>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            track('Add Venmo Click', { venmoHandle });
            updateEntertainerAsync({
              contact: {
                ...(entertainer.contact ?? {}),
                venmo: sanitizeInstagramUsername(venmoHandle),
              },
            });
          }}
        >
          Add Venmo
        </Button>
      </Card.Footer>
    </Card>
  );
};

export const useCompetitionInfo = ({
  competition,
}: {
  competition: { startDate: string; endDate: string };
}) => {
  const status = useMemo(() => {
    if (dayjs(competition.endDate).isBefore(dayjs(), 'date')) {
      return 'Completed';
    }

    if (dayjs(competition.startDate).isAfter(dayjs(), 'date')) {
      return 'Upcoming';
    }

    return 'Active';
  }, [competition.startDate, competition.endDate]);

  return {
    status,
  };
};

const CompetitionCard: FC<{ competition: EntertainerCompetition }> = ({ competition }) => {
  const { navigate } = useRouter();
  const { track } = useAnalytics();

  const { status } = useCompetitionInfo({ competition });

  return (
    <Card>
      <Card.Header
        title={competition.title}
        subtitle={competition.subtitle}
        action={
          <Chip
            className="py-1 px-2"
            variant="outlined"
            color="neutral"
            label={<span style={{ fontSize: '.7rem' }}>{status}</span>}
          />
        }
      />
      <Card.Footer>
        <Button
          onClick={() => {
            track('Click Enterainer Competition Card');
            navigate(`/manage/entertainer/competition/${competition._id}`, {
              state: { back: '/manage/entertainer' },
            });
          }}
        >
          Details
        </Button>
      </Card.Footer>
    </Card>
  );
};

const INFINITE_PROFILE_BOOST_ENABLED = false;

export const ManageEntertainerProfileScreen: FC<{ back: string }> = ({ back }) => {
  const {
    entertainerIsReady,
    entertainer,
    bookingRequests,
    bookingOpportunities,
    upcomingEvents,
    eventRequests,
    competitions,
    refetchEntertainer,
  } = useCurrentEntertainerQuery();
  const { navigate, back: navigateBack } = useRouter();
  const { track } = useAnalytics();
  const [imageUploadIsVisible, setImageUploadIsVisible] = useState(false);
  const [updatedImage, setUpdatedImage] = useState<any>();
  const { isSubmitting, updateEntertainerAsync } = useUpdateEntertainerMutation();
  const { deleteEntertainerEventAsync } = useDeleteEntertainerEventMutation(entertainer?._id);

  const hasEntertainerProfile = entertainerIsReady && !!entertainer?.title;
  const { userConversations } = useUserConversationsQuery();
  const { openConfirmDialog } = useDialog();

  const { didCopy, shareLink } = useShareLink();

  const handleShareLink = async () => {
    const bookingHandle = entertainer.bookingHandle;
    const bookingLink = `https://werqwith.me/${bookingHandle}`;

    track('Share Entertainer Profile (Empty Booking Requests)');

    await shareLink({ url: bookingLink });
  };

  const handlePreviewProfile = () => {
    track('Click Preview Profile');
    navigate(`/noauth/entertainer/${entertainer.bookingHandle}`, {
      state: { back: '/manage/entertainer', preview: true },
    });
  };

  const handleCreateEvent = () => {
    navigate('/manage/entertainer/events/create');
  };

  if (!hasEntertainerProfile) {
    return <OnboardEntertainerScreen back={back} entertainer={entertainer} />;
  }

  const activeCompetitions = competitions.filter(({ endDate }) =>
    dayjs(endDate).isSameOrAfter(dayjs(), 'date'),
  );

  return (
    <Screen
      name="Manage Entertailer Profile Screen"
      // hideToolbar
      onReenter={() => {
        refetchEntertainer();
      }}
      headerProps={{
        back: back ? { to: back } : undefined,
        left: <></>,
        // left: back ? undefined : (
        //   <Button
        //     startIcon={<FontAwesomeIcon name="chevron-left" />}
        //     variant="smooth"
        //     color="neutral"
        //     roundness="rounded"
        //     className="py-2"
        //     onClick={() => navigateBack('/profile')}
        //   >
        //     Events
        //   </Button>
        // ),
        // title: <Screen.GagglTitle />,
        // right: userConversations.entertainer.length ? (
        //   <IconButton
        //     color="neutral"
        //     variant="smooth"
        //     badge={userConversations.entertainer.some(({ isUnread }) => isUnread)}
        //     onClick={() => {
        //       navigate('/conversations/entertainer', {
        //         state: { back: '/manage/entertainer' },
        //       });
        //     }}
        //   >
        //     <FontAwesomeIcon name="paper-plane" />
        //   </IconButton>
        // ) : (
        //   <></>
        // ),
      }}
    >
      <Screen.Content maxWidth={425}>
        {entertainerIsReady ? (
          <div style={{ paddingBottom: 4 * 20 }}>
            <div>
              <TitleToolbar className="mb-0" text={entertainer.title} />

              <BookingHandleInput entertainer={entertainer} />

              {INFINITE_PROFILE_BOOST_ENABLED && (
                <Button
                  className="mt-2"
                  startIcon={<FontAwesomeIcon name="chart-line" />}
                  roundness="rounded"
                >
                  Enable Infinite Profile Boost
                </Button>
              )}

              {/* <Card className="w-100">
                <Card.Body>
                  <div className="d-flex align-items-center w-100">
                    <div>
                      <div>
                        <strong>Wanna move up the leaderboard?</strong>
                      </div>
                      <div className="mb-2">{`Share your "werqwith.me" link with your fans for quick ratings and move up the rankings.`}</div>
                      <BookingHandleInput entertainer={entertainer} />
                    </div>
                  </div>
                </Card.Body>
              </Card> */}

              <div className="d-grid grid-cols-2 gap-2 mt-2">
                <div>
                  {imageUploadIsVisible || !entertainer.avatar ? (
                    <>
                      <Card>
                        <ImageUploadField
                          placeholder="Add a profile image..."
                          onChange={setUpdatedImage}
                        />
                      </Card>
                    </>
                  ) : (
                    <Card>
                      <AsyncImage
                        style={{ aspectRatio: 1, objectFit: 'cover' }}
                        src={entertainer.avatar}
                        onError={() => setImageUploadIsVisible(true)}
                      />
                    </Card>
                  )}
                </div>
                {RANKINGS_NAVIGATION_ENABLED ? (
                  <>
                    {!!entertainer?.cities?.length && (
                      <>
                        {entertainer?.cities?.map((city, i) => (
                          <div
                            key={city._id}
                            style={
                              entertainer?.cities?.length % 2 === 0 &&
                              i % 2 &&
                              i === entertainer?.cities?.length - 1
                                ? {
                                    gridColumn: 'span 2',
                                  }
                                : undefined
                            }
                          >
                            <Card className="h-100">
                              <Card.Body
                                onClick={() => {
                                  track('Click Leaderboard Card');
                                  navigate(
                                    `/noauth/entertainers/${entertainer.type}/${city._id}/rankings`,
                                    {
                                      state: { back: '/manage/entertainer' },
                                    },
                                  );
                                }}
                              >
                                <div className="mb-2">Leaderboard 👑</div>
                                <strong className="fs-5">{`Top ${pluralize(TYPES_RECORD[entertainer.type])} in ${city.name}`}</strong>
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <div>
                    <BadgeSection size="half" />
                  </div>
                )}
              </div>

              {updatedImage && (
                <div className="mt-2">
                  <Button
                    disabled={isSubmitting}
                    onClick={async () => {
                      await updateEntertainerAsync({ avatar: updatedImage });
                      setImageUploadIsVisible(false);
                    }}
                  >
                    Update Image
                  </Button>
                </div>
              )}
              <div className="d-flex mt-2 gap-2">
                <Button
                  className="py-2 px-3"
                  roundness="rounded"
                  variant="smooth"
                  color="neutral"
                  onClick={() => navigate('/manage/entertainer/edit')}
                >
                  Edit Profile
                </Button>
                <Button
                  className="py-2 px-3"
                  roundness="rounded"
                  variant="smooth"
                  color="neutral"
                  onClick={handlePreviewProfile}
                >
                  Preview Profile
                </Button>
              </div>

              <VenmoCard entertainer={entertainer} />

              <FeaturedPhotoManagementSection featuredMedia={entertainer.featuredMedia} />

              {!!eventRequests?.length && (
                <div className="d-flex mt-2 gap-2 w-100">
                  <Card className="w-100">
                    <Card.Body
                      onClick={() => {
                        track('Click Event Requests Card');
                        navigate(`/manage/entertainer/event-requests`);
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center w-100">
                        <div>
                          <div>Tagged Events 🗓️</div>
                          <strong className="fs-5">{`You have ${eventRequests?.length} tagged ${pluralize('events', eventRequests?.length)}`}</strong>
                        </div>
                        <div className="ml-auto ps-2">
                          <FontAwesomeIcon name="chevron-right" />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )}

              {!!activeCompetitions?.length && (
                <>
                  <TitleToolbar text="Competitions 👑" size="md" />
                  <HorizontalScroll
                    items={
                      activeCompetitions.map((competition) => (
                        <CompetitionCard key={competition._id} competition={competition} />
                      )) ?? []
                    }
                  />
                </>
              )}

              <TitleToolbar text="Booking Requests 📚" size="md" />
              {/* <div>
                <FormLabel text="Booking Link" />
                <div>
                  <BookingHandleInput entertainer={entertainer} />
                </div>
              </div> */}
              {bookingRequests?.length ? (
                <HorizontalScroll
                  items={
                    bookingRequests.map((bookingRequest) => (
                      <BookingRequestCard
                        key={bookingRequest._id}
                        bookingRequest={bookingRequest}
                      />
                    )) ?? []
                  }
                />
              ) : (
                <EmptyState
                  title="You don't have any pending booking requests"
                  // text="Share your profile to get more bookings"
                  content={
                    <>
                      <p className="mt-0 mb-2">
                        Add your profile link to your social media accounts to get more bookings,
                        receive tips, and improve your ratings.
                      </p>
                      <div className="mb-3">
                        <span
                          className="px-3 py-1"
                          style={{
                            display: 'inline-block',
                            border: '1px solid rgba(255, 255, 255, 0.5)',
                            borderRadius: '0.5rem',
                            background: 'rgba(255, 255, 255, 0.1)',
                          }}
                        >{`werqwith.me/${entertainer.bookingHandle}`}</span>
                      </div>
                    </>
                  }
                  button={{
                    text: didCopy ? 'Profile Link Copied!' : 'Share Profile Link',
                    onClick: handleShareLink,
                  }}
                />
              )}

              <TitleToolbar text="Gig Opportunities 💃🏽" size="md" />
              <BookingOpportunitiesContent
                entertainer={entertainer}
                bookingOpportunities={bookingOpportunities ?? []}
              />

              <TitleToolbar
                text="Manage Upcoming Events 🎉"
                size="md"
                action={
                  <Button
                    variant="flat"
                    size="sm"
                    startIcon={<FontAwesomeIcon name="plus" />}
                    onClick={handleCreateEvent}
                  >
                    Event
                  </Button>
                }
              />
              {upcomingEvents?.length ? (
                <HorizontalScroll
                  items={
                    upcomingEvents.map((event) => (
                      <Card
                        key={event._id}
                        className="event-card"
                        onClick={() => {
                          track('Click Enterainer Event Card');
                          openConfirmDialog({
                            title: "We're sorry!",
                            text: "You can't edit events yet. If you would like to make changes, please delete the event and create it again. We're very sorry for the inconvenience.",
                          });
                          // navigate(`/manage/entertainer/events/${event._id}`, {
                          //   state: { back: '/manage/entertainer' },
                          // });
                        }}
                      >
                        <EventHeader
                          event={event}
                          hideRsvp
                          action={
                            event.canUserEdit ? (
                              <IconButton
                                color="danger"
                                variant="smooth"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  openConfirmDialog({
                                    title: 'Delete Event',
                                    text: 'Are you sure you want to delete this event?',
                                    onConfirm: async () => {
                                      await deleteEntertainerEventAsync({
                                        eventId: event._id,
                                      });
                                    },
                                  });
                                }}
                              >
                                <FontAwesomeIcon name="trash" faStyle="far" />
                              </IconButton>
                            ) : undefined
                          }
                        />
                        <EventImage event={event} />
                      </Card>
                    )) ?? []
                  }
                />
              ) : (
                <EmptyState
                  title="No upcoming events"
                  text="You aren't sharing any upcoming events. Add an event to improve your reach."
                  button={{ text: 'Add Event', onClick: handleCreateEvent }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
      </Screen.Content>
      {!!entertainer.featuredMedia?.length && (
        <div className="fab fab-center-bottom">
          <div className="d-flex gap-2">
            <Button
              startIcon={<FontAwesomeIcon name="share" />}
              variant="smooth"
              color="neutral"
              style={{ background: '#181818' }}
              onClick={handleShareLink}
            >
              Share
            </Button>
            <Button
              startIcon={<FontAwesomeIcon name="eye" />}
              variant="smooth"
              color="neutral"
              style={{ background: '#181818' }}
              onClick={handlePreviewProfile}
            >
              Preview
            </Button>
          </div>
        </div>
      )}
    </Screen>
  );
};
