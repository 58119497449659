export const ScheduleIcon = ({
  height,
  width,
}: {
  height?: number | string;
  width?: number | string;
}) => (
  <svg
    version="1.1"
    id="fi_771496"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    {...{ height, width }}
  >
    <path
      style={{ fill: '#E4E9F4' }}
      d="M483.516,502.968h-454C13.282,502.968,0,489.685,0,473.452V179.355l257-10l255,10v295.129
	C512,490.215,499.247,502.968,483.516,502.968z"
    ></path>
    <path
      style={{ fill: '#FF6464' }}
      d="M512,179.355H0V85.484c0-16.569,13.431-30,30-30h450.968c16.569,0,31.032,13.431,31.032,30V179.355z"
    ></path>
    <path
      style={{ fill: '#CDD2DF' }}
      d="M459.355,179.355v137.806c0,85.515-69.324,154.839-154.839,154.839H0
	c0,17.103,11.897,30.968,29,30.968h456.016c14.903,0,26.984-12.081,26.984-26.984V179.355H459.355z"
    ></path>
    <g>
      <path
        style={{ fill: '#49BC6E' }}
        d="M250.506,362.23c-2.053,0-4.023-0.816-5.474-2.267l-18.248-18.248c-3.024-3.024-3.024-7.925,0-10.95
		c3.023-3.022,7.925-3.022,10.949,0l12.774,12.774l27.372-27.373c3.023-3.022,7.925-3.022,10.949,0c3.024,3.024,3.024,7.925,0,10.95
		l-32.846,32.847C254.529,361.414,252.559,362.23,250.506,362.23z"
      ></path>
      <path
        style={{ fill: '#49BC6E' }}
        d="M157.603,362.23c-2.053,0-4.023-0.816-5.474-2.267l-18.248-18.248c-3.024-3.024-3.024-7.925,0-10.95
		c3.023-3.022,7.925-3.022,10.949,0l12.774,12.774l27.372-27.373c3.023-3.022,7.925-3.022,10.949,0c3.024,3.024,3.024,7.925,0,10.95
		l-32.846,32.847C161.626,361.414,159.656,362.23,157.603,362.23z"
      ></path>
      <path
        style={{ fill: '#49BC6E' }}
        d="M64.7,362.23c-2.053,0-4.023-0.816-5.474-2.267l-18.248-18.248c-3.024-3.024-3.024-7.925,0-10.95
		c3.023-3.022,7.925-3.022,10.949,0L64.7,343.539l27.372-27.373c3.023-3.022,7.925-3.022,10.949,0c3.024,3.024,3.024,7.925,0,10.95
		l-32.846,32.847C68.722,361.414,66.753,362.23,64.7,362.23z"
      ></path>
    </g>
    <path
      style={{ fill: '#ED4545' }}
      d="M480,55.484h-51.613c17.103,0,30.968,13.865,30.968,30.968v92.903H512V86.452
	C512,69.349,497.103,55.484,480,55.484z"
    ></path>
    <path
      style={{ fill: '#7A6CF7' }}
      d="M86.018,268.86h-32.68c-3.803,0-6.886-3.083-6.886-6.886v-32.68c0-3.803,3.083-6.886,6.886-6.886
	h32.68c3.803,0,6.886,3.083,6.886,6.886v32.68C92.903,265.777,89.82,268.86,86.018,268.86z"
    ></path>
    <g>
      <path
        style={{ fill: '#49BC6E' }}
        d="M343.409,269.799c-1.981,0-3.963-0.756-5.474-2.267l-18.248-18.248
		c-3.024-3.024-3.024-7.925,0-10.95c3.023-3.022,7.925-3.022,10.949,0l12.774,12.774l27.372-27.372
		c3.024-3.022,7.926-3.022,10.949,0c3.024,3.024,3.024,7.925,0,10.95l-32.846,32.846
		C347.372,269.043,345.39,269.799,343.409,269.799z"
      ></path>
      <path
        style={{ fill: '#49BC6E' }}
        d="M250.506,269.799c-1.981,0-3.963-0.756-5.474-2.267l-18.248-18.248
		c-3.024-3.024-3.024-7.925,0-10.95c3.023-3.022,7.925-3.022,10.949,0l12.774,12.774l27.372-27.372
		c3.024-3.022,7.926-3.022,10.949,0c3.024,3.024,3.024,7.925,0,10.95l-32.846,32.846
		C254.469,269.043,252.487,269.799,250.506,269.799z"
      ></path>
      <path
        style={{ fill: '#49BC6E' }}
        d="M157.603,269.799c-1.981,0-3.963-0.756-5.474-2.267l-18.248-18.248
		c-3.024-3.024-3.024-7.925,0-10.95c3.023-3.022,7.925-3.022,10.949,0l12.774,12.774l27.372-27.372
		c3.024-3.022,7.926-3.022,10.949,0c3.024,3.024,3.024,7.925,0,10.95l-32.846,32.846
		C161.566,269.043,159.584,269.799,157.603,269.799z"
      ></path>
    </g>
    <g>
      <path
        style={{ fill: '#FF6464' }}
        d="M371.926,314.525c-3.023-3.022-7.925-3.022-10.949,0l-12.59,12.59l-12.59-12.59
		c-3.023-3.022-7.925-3.022-10.949,0c-3.024,3.024-3.024,7.925,0,10.95l12.59,12.59l-12.59,12.59c-3.024,3.024-3.024,7.925,0,10.95
		c1.512,1.511,3.493,2.267,5.474,2.267s3.963-0.756,5.474-2.267l12.59-12.59l12.59,12.59c1.512,1.511,3.493,2.267,5.474,2.267
		s3.963-0.756,5.474-2.267c3.024-3.024,3.024-7.925,0-10.95l-12.59-12.59l12.59-12.59
		C374.95,322.451,374.95,317.549,371.926,314.525z"
      ></path>
      <path
        style={{ fill: '#FF6464' }}
        d="M452.239,245.634l12.59-12.59c3.024-3.024,3.024-7.925,0-10.95c-3.023-3.022-7.925-3.022-10.949,0
		l-12.59,12.59l-12.59-12.59c-3.023-3.022-7.925-3.022-10.949,0c-3.024,3.024-3.024,7.925,0,10.95l12.59,12.59l-12.59,12.59
		c-3.024,3.024-3.024,7.925,0,10.95c1.512,1.511,3.493,2.267,5.474,2.267s3.963-0.756,5.474-2.267l12.59-12.59l12.59,12.59
		c1.512,1.511,3.493,2.267,5.474,2.267s3.963-0.756,5.474-2.267c3.024-3.024,3.024-7.925,0-10.95L452.239,245.634z"
      ></path>
    </g>
    <g>
      <path
        style={{ fill: '#7A6CF7' }}
        d="M457.631,361.29h-32.68c-3.803,0-6.886-3.083-6.886-6.886v-32.68c0-3.803,3.083-6.886,6.886-6.886
		h32.68c3.803,0,6.886,3.083,6.886,6.886v32.68C464.516,358.208,461.433,361.29,457.631,361.29z"
      ></path>
      <path
        style={{ fill: '#7A6CF7' }}
        d="M86.018,454.194h-32.68c-3.803,0-6.886-3.083-6.886-6.886v-32.68c0-3.803,3.083-6.886,6.886-6.886
		h32.68c3.803,0,6.886,3.083,6.886,6.886v32.68C92.903,451.111,89.82,454.194,86.018,454.194z"
      ></path>
      <path
        style={{ fill: '#7A6CF7' }}
        d="M178.921,454.194h-32.68c-3.803,0-6.886-3.083-6.886-6.886v-32.68c0-3.803,3.083-6.886,6.886-6.886
		h32.68c3.803,0,6.886,3.083,6.886,6.886v32.68C185.806,451.111,182.724,454.194,178.921,454.194z"
      ></path>
      <path
        style={{ fill: '#7A6CF7' }}
        d="M271.824,454.194h-32.68c-3.803,0-6.886-3.083-6.886-6.886v-32.68c0-3.803,3.083-6.886,6.886-6.886
		h32.68c3.803,0,6.886,3.083,6.886,6.886v32.68C278.71,451.111,275.627,454.194,271.824,454.194z"
      ></path>
      <path
        style={{ fill: '#7A6CF7' }}
        d="M364.727,454.194h-32.68c-3.803,0-6.886-3.083-6.886-6.886v-32.68c0-3.803,3.083-6.886,6.886-6.886
		h32.68c3.803,0,6.886,3.083,6.886,6.886v32.68C371.613,451.111,368.53,454.194,364.727,454.194z"
      ></path>
    </g>
    <g>
      <path
        style={{ fill: '#555E6B' }}
        d="M115.823,105.032h-1.452c-12.426,0-22.5-10.074-22.5-22.5v-51c0-12.426,10.074-22.5,22.5-22.5h1.452
		c12.426,0,22.5,10.074,22.5,22.5v51C138.323,94.959,128.249,105.032,115.823,105.032z"
      ></path>
      <path
        style={{ fill: '#555E6B' }}
        d="M396.597,105.032h-1.452c-12.426,0-22.5-10.074-22.5-22.5v-51c0-12.426,10.074-22.5,22.5-22.5h1.452
		c12.426,0,22.5,10.074,22.5,22.5v51C419.097,94.959,409.023,105.032,396.597,105.032z"
      ></path>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
