import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import * as dayjs from 'dayjs';

import './DatePicker.scss';

export const useDatePickerState = ({
  date: initialDate,
  time: initialTime,
}: {
  date?: string;
  time?: string;
}) => {
  const [date, setDate] = useState<string>(initialDate);
  const [time, setTime] = useState<string>(initialTime);

  const handleUpdateDateTime = (updatedDateTime: Date) => {
    const updatedDate = dayjs(updatedDateTime).format('YYYY-MM-DD');
    const updatedTime = dayjs(updatedDateTime).format('HH:mm');
    setDate(updatedDate);
    setTime(updatedTime);

    return { date: updatedDate, time: updatedTime };
  };

  const dateTime = useMemo(() => {
    if (date && time) {
      return `${date?.toString()} ${time?.toString()}`.trim();
    }

    return undefined;
  }, [date, time]);

  return { date, time, dateTime, handleUpdateDateTime };
};

interface DatePickerProps {
  showTimeSelect?: boolean;
  placeholder?: string;
  value?: string;
  minDate?: string;
  onChange: (updatedValue: Date) => void;
  direction?: 'top' | 'bottom';
  name?: string;
  dateFormat?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
  showTimeSelect,
  placeholder,
  value: stringValue,
  minDate: stringMinDate,
  dateFormat,
  onChange,
  direction = 'top',
  name,
}) => {
  const date = stringValue ? dayjs(stringValue) : undefined;
  const value = date && date.isValid() ? date.toDate() : '';

  const dayjsMinDate = dayjs(stringMinDate);
  const minDate = dayjsMinDate.isValid() ? dayjsMinDate.toDate() : undefined;

  const filterPassedTime = (time) => {
    if (minDate) {
      const selectedDate = new Date(time);

      return minDate.getTime() < selectedDate.getTime();
    }

    return true;
  };

  useEffect(() => {
    if (date && minDate) {
      if (date.isBefore(minDate)) {
        onChange(minDate);
      }
    }
  }, [dayjs(minDate).format('YYYY-MM-DD h:mma')]);

  return (
    <ReactDatePicker
      name={name}
      showTimeSelect={showTimeSelect}
      placeholderText={placeholder}
      selected={value}
      minDate={minDate}
      filterTime={filterPassedTime}
      onChange={onChange}
      popperPlacement={direction}
      dateFormat={dateFormat ?? (showTimeSelect ? 'M/d/YY @ h:mma' : 'M/d/YY')}
    />
  );
};
