/**
 * Checks if a string is a valid MongoDB ObjectId.
 * @param id - The string to check.
 * @returns True if the string is a valid ObjectId, false otherwise.
 */
export function isValidObjectId(id: string): boolean {
  // Check if the input is a string and matches the ObjectId format
  const objectIdPattern = /^[a-f\d]{24}$/i;
  return typeof id === 'string' && objectIdPattern.test(id);
}
