import { useMemo } from 'react';

import dayjs from 'dayjs';
import { capitalize, groupBy } from 'lodash';

import {
  BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES,
  PublicBookingOpportunity,
} from '@queries/PromoterQueries';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import Card from '@components/layout/Card/Card';

import { formatStatus } from '@screens/entertainer/BookingOpportunityCard';

import { ShareBookingOpportunityButton } from '../ManagePromoterBookingOpportunityScreen/ManagePromoterBookingOpportunityScreen';

export const OpenBookingCard = ({
  bookingOpportunity,
}: {
  bookingOpportunity: PublicBookingOpportunity;
}) => {
  const navigate = useNavigate();
  const subtitle = useMemo(() => {
    if (!bookingOpportunity.entertainers.length) {
      return 'No Interested Entertainers';
    }
    const statusGroups = groupBy(bookingOpportunity.entertainers, 'status');

    return BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES.filter(
      (status) => statusGroups?.[status]?.length,
    )
      .map((status) => {
        const entertainers = statusGroups[status] ?? [];
        return `${entertainers.length} ${formatStatus(status)}`;
      })
      .join(' | ');
  }, [bookingOpportunity.entertainers]);

  return (
    <Card>
      <Card.Header
        tagline={`${dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY')} | ${bookingOpportunity.city?.name}`}
        title={bookingOpportunity.title}
        subtitle={subtitle}
        action={
          <ShareBookingOpportunityButton
            bookingOpportunityId={bookingOpportunity._id}
            buttonProps={{ variant: 'outlined' }}
          />
        }
      />
      <Card.Footer>
        <Button
          color="neutral"
          variant="smooth"
          roundness="rounded"
          onClick={() => {
            navigate(`/manage/promoter/open-bookings/${bookingOpportunity._id}`);
          }}
        >
          Manage
        </Button>
      </Card.Footer>
    </Card>
  );
};
