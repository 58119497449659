import { FC, MutableRefObject, ReactElement } from 'react';

import './TitleToolbar.scss';

interface TitleToolbarProps {
  className?: string;
  text: string | ReactElement;
  size?: 'sm' | 'md' | 'lg';
  action?: ReactElement;
  divRef?: MutableRefObject<HTMLDivElement | null>;
}
export const TitleToolbar: FC<TitleToolbarProps> = ({
  className = '',
  size = 'lg',
  text,
  action,
  divRef,
}) => {
  return (
    <div
      ref={divRef}
      className={`title-toolbar title-toolbar-size-${size} d-flex justify-content-between align-items-center ${className}`}
    >
      <h1 className="title">{text}</h1>
      {action && <div className="title-action text-nowrap">{action}</div>}
    </div>
  );
};
