import { Badge } from 'framework7-react';
import { FC } from 'react';

import BadgeComponent from 'framework7/components/badge/badge';

import {
  useCurrentEntertainerBadgesQuery,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';

import { useAnalytics } from '@hooks/useAnalytics';

import { useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import { SendBadgeIcon } from '@components/buttons/SendBadgeIcon';
import { Chip } from '@components/chips/Chip';
import Card from '@components/layout/Card/Card';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';

export const BadgeSection: FC<{ size?: 'half' | 'full' }> = ({ size }) => {
  const { track } = useAnalytics();
  const { navigate } = useRouter();
  const { entertainerIsReady, entertainer } = useCurrentEntertainerQuery();
  const {
    entertainerBadgesAreReady,
    sentBadges = [],
    receivedBadges = [],
  } = useCurrentEntertainerBadgesQuery(entertainer?._id);

  if (!entertainer) {
    return <></>;
  }

  const lockedBadges = receivedBadges?.filter((badge) => badge.isLocked) ?? [];

  if (!entertainerIsReady || !entertainerBadgesAreReady) {
    return <Skeleton height={136} />;
  }

  if (size === 'full') {
    return (
      <Card>
        <Card.Body className="pb-2">
          <div className="d-flex gap-3 align-items-center">
            <div style={{ width: '20%' }}>
              <div className="d-flex align-items-center">
                <SendBadgeIcon height={60} width={59} />
              </div>
            </div>
            <div style={{ width: '80%' }}>
              <p className="my-0 fs-6">
                <strong>Badges</strong>: {entertainer.title}
              </p>
              <div
                className="mt-1 d-flex gap-2 justify-content-center"
                style={{ border: '1px solid rgba(255,255,255,.2)', borderRadius: 8 }}
              >
                <Chip
                  className="py-0 px-2"
                  color="default"
                  label={<strong style={{ fontSize: 12 }}>{`${sentBadges.length} Given`}</strong>}
                />
                <Chip
                  className="py-0 px-2"
                  color="default"
                  label={
                    <strong style={{ fontSize: 12 }}>{`${receivedBadges.length} Earned`}</strong>
                  }
                />
                {!!lockedBadges?.length && (
                  <Chip
                    className="py-0 px-2"
                    color="default"
                    label={
                      <strong style={{ fontSize: 12 }}>{`${lockedBadges.length} Locked`}</strong>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <Button
            roundness="rounded"
            color="neutral"
            variant="smooth"
            endIcon={
              lockedBadges?.length ? (
                <div style={{ height: 20, marginTop: -10, marginBottom: -10 }}>
                  <Badge className="px-2" color="red">
                    {lockedBadges?.length}
                  </Badge>
                </div>
              ) : undefined
            }
            onClick={() => {
              track('View Badges');
              navigate(`/manage/entertainer/badges`);
            }}
          >
            See Your Badges
          </Button>
        </Card.Footer>
      </Card>
    );
  }

  return (
    <Card className="h-100">
      <Card.Body className="pb-2">
        <div className="d-flex gap-3 align-items-center">
          <div className="w-100">
            <div className="d-flex align-items-center gap-2">
              <SendBadgeIcon height={24} width={24} />
              <div className="my-0 fs-5">
                <strong>Badges</strong>
              </div>
            </div>
            <div
              className="mt-1 d-flex gap-2 justify-content-center"
              style={{ border: '1px solid rgba(255,255,255,.2)', borderRadius: 8 }}
            >
              <Chip
                className="py-0 px-2"
                color="default"
                label={<strong style={{ fontSize: 12 }}>{`${sentBadges.length} Given`}</strong>}
              />
              <Chip
                className="py-0 px-2"
                color="default"
                label={
                  <strong style={{ fontSize: 12 }}>{`${receivedBadges.length} Earned`}</strong>
                }
              />
              {!!lockedBadges?.length && (
                <Chip
                  className="py-0 px-2"
                  color="default"
                  label={
                    <strong style={{ fontSize: 12 }}>{`${lockedBadges.length} Locked`}</strong>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <Button
          roundness="rounded"
          color="neutral"
          variant="smooth"
          endIcon={
            lockedBadges?.length ? (
              <div style={{ height: 20, marginTop: -10, marginBottom: -10 }}>
                <Badge className="px-2" color="red">
                  {lockedBadges?.length}
                </Badge>
              </div>
            ) : undefined
          }
          onClick={() => {
            track('View Badges');
            navigate(`/manage/entertainer/badges`);
          }}
        >
          See Your Badges
        </Button>
      </Card.Footer>
    </Card>
  );
};
