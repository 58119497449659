import { Badge } from 'framework7-react';
import { FC, useEffect } from 'react';

import BadgeComponent from 'framework7/components/badge/badge';
import pluralize from 'pluralize';
import { TALENT_CONNECTOR_ENABLED } from 'src/constants/FeatureFlags';

import {
  useCityEntertainerAvailabilityQuery,
  useCurrentEntertainerBadgesQuery,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';
import { useCityBookingOpportunitiesQuery } from '@queries/PromoterQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import { ScheduleIcon } from '@components/buttons/ScheduleIcon';
import { SendBadgeIcon } from '@components/buttons/SendBadgeIcon';
import { Chip } from '@components/chips/Chip';
import Card from '@components/layout/Card/Card';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';

import { PrimaryBookingWizardEntryPoint } from '@screens/booking/BookingWizardScreen/BookingWizardScreen';

export const BookingSection = () => {
  const { track } = useAnalytics();
  const { hasOnboardedBookingWizard, entertainer, bookingOpportunities } =
    useCurrentEntertainerQuery();
  const { currentCity } = useCurrentUserCity();
  const { cityEntertainerAvailabilityIsReady, cityEntertainerAvailability } =
    useCityEntertainerAvailabilityQuery(currentCity?._id);
  const { navigate } = useRouter();

  useEffect(() => {
    if (TALENT_CONNECTOR_ENABLED) {
      track('Booking Section Impression');
    }
  }, []);

  if (!TALENT_CONNECTOR_ENABLED) {
    return <></>;
  }

  if (currentCity?._id && entertainer?._id) {
    if (!cityEntertainerAvailabilityIsReady) {
      return <Skeleton height={136} />;
    }

    if (hasOnboardedBookingWizard) {
      const bookingOpportunitiesCount = bookingOpportunities?.length;
      const openGigsText = `${bookingOpportunitiesCount} Open ${pluralize('Gig', bookingOpportunitiesCount)}`;

      const availableEntertainerCount = Object.keys(
        cityEntertainerAvailability?.entertainersById ?? {},
      )?.length;
      const availableEntertainersText = `${availableEntertainerCount} Available ${pluralize('Entertainer', availableEntertainerCount)}`;

      return (
        <Card>
          <Card.Body className="pb-2">
            <div className="d-flex gap-3 align-items-center">
              <div style={{ width: '20%' }}>
                <div className="d-flex align-items-center">
                  <ScheduleIcon height={48} width="100%" />
                </div>
              </div>
              <div style={{ width: '80%' }}>
                <p className="my-0 fs-6">
                  <strong>Schedule</strong>: {currentCity.name}
                </p>
                <div
                  className="mt-1 d-flex gap-2 justify-content-center"
                  style={{ border: '1px solid rgba(255,255,255,.2)', borderRadius: 8 }}
                >
                  <Chip
                    className="py-0 px-2"
                    color="default"
                    label={<strong style={{ fontSize: 12 }}>{openGigsText}</strong>}
                  />
                  <Chip
                    className="py-0 px-2"
                    color="default"
                    label={<strong style={{ fontSize: 12 }}>{availableEntertainersText}</strong>}
                  />
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <Button
              roundness="rounded"
              color="neutral"
              variant="smooth"
              onClick={() => {
                track('View Gigs & Talent');
                navigate(`/gigs-and-talent`);
              }}
            >
              See Open Gigs & Talent
            </Button>
          </Card.Footer>
        </Card>
      );
    }

    return (
      <PrimaryBookingWizardEntryPoint
        onClickEntertainer={() => {
          navigate('/noauth/booking-wizard/entertainer');
        }}
        onClickPromoter={() => {
          navigate('/noauth/booking-wizard/promoter');
        }}
      />
    );
  }

  return <></>;
};
