import { FC, useEffect, useMemo, useState } from 'react';

import { useSplashNotifications } from '@queries/UserQueries';

import { useMarkSplashNotificationAsReadMutation } from '@mutations/UserMutations';

import { useLocation, useNavigate, useParams } from '@router/index';

import { isValidObjectId } from '@utilities/isValidObjectId';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

export const SplashNotificationPopup: FC<{ staticPath: string; dynamicPath: string }> = ({
  staticPath,
  dynamicPath,
}) => {
  const { splashNotifications = [] } = useSplashNotifications();
  const [readRecord, setReadRecord] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const splashNotification = useMemo(() => {
    console.log('recalculating splash notification');
    return splashNotifications
      .filter((notification) => !readRecord[notification._id])
      .find(
        (notification) =>
          notification.displayPath === '*' ||
          notification.displayPath === staticPath ||
          notification.displayPath === dynamicPath,
      );
  }, [splashNotifications, staticPath, dynamicPath]);

  const { markSplashNotificationAsReadAsync } = useMarkSplashNotificationAsReadMutation();
  useEffect(() => {
    if (isValidObjectId(splashNotification?._id)) {
      markSplashNotificationAsReadAsync({ notificationId: splashNotification._id });
    }
  }, [splashNotification?._id]);

  const handleClose = () => {
    if (splashNotification) {
      setReadRecord((prev) => ({ ...prev, [splashNotification._id]: true }));
    }
  };

  return (
    <Popup isVisible={!!splashNotification?._id} onClose={handleClose}>
      <Popup.Content>
        {splashNotification && (
          <>
            {splashNotification.imageUrl && (
              <div>
                <img
                  src={splashNotification.imageUrl}
                  style={{ display: 'block', height: 140, margin: '0 auto' }}
                />
              </div>
            )}
            <TitleToolbar text={splashNotification.title} size="md" />
            {mapStringToParagraphs(splashNotification.content, { collapseTopMargin: true })}
            {splashNotification.button && (
              <Button
                roundness="rounded"
                onClick={() => {
                  handleClose();
                  if (splashNotification.button?.to) {
                    navigate(splashNotification.button.to);
                  }
                }}
              >
                {splashNotification.button.text}
              </Button>
            )}
          </>
        )}
      </Popup.Content>
    </Popup>
  );
};
