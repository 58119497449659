import {
  Link,
  Message,
  Messagebar,
  MessagebarAttachment,
  MessagebarAttachments,
  MessagebarSheet,
  MessagebarSheetImage,
  Messages,
  MessagesTitle,
  NavLeft,
  NavTitle,
  Navbar,
  Page,
  f7,
  f7ready,
} from 'framework7-react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Messagebar as MessagebarType } from 'framework7/types';

import { useParams } from '@router/index';

import { useDialog } from '@components/layout/Dialog/useDialog';

import { ChatParams, useChatMessages } from './useChatMessages';

const ENABLE_ATTACHMENTS = false;
const ENABLE_TYPING_MESSAGE = false;

export const ChatScreen = () => {
  const params = useParams<ChatParams>();

  const {
    messagesAreReady,
    messageText,
    setMessageText,
    messagesData,
    sendMessage,
    images,
    attachments,
    deleteAttachment,
    handleAttachment,
    sheetVisible,
    setSheetVisible,
  } = useChatMessages(params);

  const messagebarRef = useRef<MessagebarType.Messagebar>(null);
  const [messageBarIsFocused, setMessageBarIsFocused] = useState(false);
  const [windowHeight, setWindowHeight] = useState<number>(undefined);

  const attachmentsVisible = () => {
    return attachments.length > 0;
  };
  useEffect(() => {
    f7ready(() => {
      messagebarRef.current = f7.messagebar.get('.messagebar');
      //   requestAnimationFrame(() => {
      //     messagebarRef.current.focus();
      //     messagebarRef.current.blur();
      //   });
    });
  });
  const isFirstMessage = (message, index) => {
    const previousMessage = messagesData[index - 1];
    if (message.isTitle) return false;
    if (
      !previousMessage ||
      previousMessage.type !== message.type ||
      previousMessage.name !== message.name
    )
      return true;
    return false;
  };
  const isLastMessage = (message, index) => {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
      return true;
    return false;
  };
  const isTailMessage = (message, index) => {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
      return true;
    return false;
  };

  const typingMessage = useMemo(
    () =>
      ENABLE_TYPING_MESSAGE
        ? {
            name: 'Kate Johnson',
            avatar: 'https://cdn.framework7.io/placeholder/people-100x100-9.jpg',
          }
        : undefined,
    [],
  );

  const handleSendMessage = async () => {
    await sendMessage();
    messagebarRef.current.focus();
  };

  // const { openAlertDialog } = useDialog();
  // useEffect(() => {
  //   const onResize = () => {
  //     console.log(window.innerHeight);
  //     setWindowHeight(
  //       200, // window.innerHeight
  //     );
  //     openAlertDialog({
  //       title: 'Height',
  //       text: window.innerHeight.toString(),
  //     });
  //   };

  //   openAlertDialog({
  //     title: 'Test',
  //     text: 'This is a test',
  //   });

  //   onResize();
  //   window.addEventListener('resize', onResize);

  //   return () => {
  //     window.removeEventListener('resize', onResize);
  //   };
  // });

  return (
    <Page
      noToolbar
      style={{
        height: messageBarIsFocused ? windowHeight : undefined,
      }}
    >
      <Navbar
        style={{
          position: messageBarIsFocused ? 'fixed' : undefined,
        }}
      >
        <NavLeft backLink />
        <NavTitle>Messages</NavTitle>
      </Navbar>

      <Messagebar
        placeholder="Message"
        attachmentsVisible={attachmentsVisible()}
        sheetVisible={sheetVisible}
        value={messageText}
        onInput={(e) => setMessageText(e.target.value)}
        // onFocus={() => setMessageBarIsFocused(true)}
        // onBlur={() => setMessageBarIsFocused(false)}
        // style={{
        //   paddingBottom: messageBarIsFocused ? 0 : undefined,
        // }}
      >
        {ENABLE_ATTACHMENTS && (
          <Link
            iconIos="f7:camera_fill"
            iconMd="material:camera_alt"
            slot="inner-start"
            onClick={() => {
              setSheetVisible(!sheetVisible);
            }}
          />
        )}
        <Link
          iconIos="f7:arrow_up_circle_fill"
          iconMd="material:send"
          slot="inner-end"
          onClick={handleSendMessage}
        />
        {ENABLE_ATTACHMENTS && (
          <>
            <MessagebarAttachments>
              {attachments.map((image, index) => (
                <MessagebarAttachment
                  key={index}
                  image={image}
                  onAttachmentDelete={() => deleteAttachment(image)}
                />
              ))}
            </MessagebarAttachments>
            <MessagebarSheet>
              {images.map((image, index) => (
                <MessagebarSheetImage
                  key={index}
                  image={image}
                  checked={attachments.indexOf(image) >= 0}
                  onChange={handleAttachment}
                />
              ))}
            </MessagebarSheet>
          </>
        )}
      </Messagebar>

      {messagesAreReady && (
        <Messages>
          {/* <MessagesTitle>
          <b>Sunday, Feb 9,</b> 12:58
        </MessagesTitle> */}

          {messagesData.map((message, index) => (
            <Message
              key={message._id}
              type={message.type}
              image={message.image}
              name={message.name}
              avatar={message.avatar}
              first={isFirstMessage(message, index)}
              last={isLastMessage(message, index)}
              tail={isTailMessage(message, index)}
            >
              {message.text && (
                <span slot="text" dangerouslySetInnerHTML={{ __html: message.text }} />
              )}
            </Message>
          ))}
          {typingMessage && (
            <Message
              type="received"
              typing={true}
              first={true}
              last={true}
              tail={true}
              header={`${typingMessage.name} is typing`}
              avatar={typingMessage.avatar}
            />
          )}
        </Messages>
      )}
    </Page>
  );
};
