import { FC, Fragment, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { map } from 'cypress/types/bluebird';
import pluralize from 'pluralize';
import { Navigation, Pagination } from 'swiper';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { FeedEvent } from '@queries/EventQueries';

import { useRespondToEntertainerEventRequestMutation } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import EventCard from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { ErrorBoundary } from '@components/layout/ErrorBoundary';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { Swiper } from '@components/layout/Swiper/Swiper';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { EditEntertainerPopup } from '../EntertainerDetailsScreen/EditEntertainerPopup';
import { AvailabilityReferralInput, BookingHandleInput } from '../ManageEntertainerProfileScreen';
import { OnboardEntertainerPopup } from '../OnboardEntertainerScreen/OnboardEntertainerScreen';
import { FeaturedPhotoManagementSection } from '../components/FeaturedPhotoManagementSection';
import profileSlide1 from './profile-1.jpg';
import profileSlide2 from './profile-2.jpg';
import profileSlide3 from './profile-3.jpg';
import profileSlide4 from './profile-4.png';
import storySlide1 from './story-1.jpeg';
import storySlide2 from './story-2.jpeg';
import storySlide3 from './story-3.jpeg';

const EventRequestCard: FC<{ event: FeedEvent }> = ({ event }) => {
  const headerTitle = event.promoters?.[0]?.title ?? event.venue?.title;

  return (
    <Card>
      <div className="reviewed-header-wrapper">
        <div className="reviewed-header">
          <span>Tagged Event</span>
          <Card.Header
            avatar={<LetterAvatar letter={findFirstAlphaCharacter(headerTitle)} />}
            title={headerTitle}
          />
        </div>
      </div>
      <EventCard hideRsvp showPromoter={false} event={event} onClick={() => {}} hideActions />
      <Card.Body className="pt-0">{mapStringToParagraphs(event.excerpt)}</Card.Body>
    </Card>
  );
};

export const ReferralCampaignScreen = () => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();
  const { userIsLoggedIn } = useAppContext();
  const { entertainer } = useCurrentEntertainerQuery();
  const [isEntertainerOnboardingPopupVisible, setIsEntertainerOnboardingPopupVisible] =
    useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);

  const [profileExampleIsVisible, setProfileExampleIsVisible] = useState(false);
  const [storyExampleIsVisible, setStoryExampleIsVisible] = useState(false);

  const handleEnter = () => {
    track('Click Enter');
    createAuthGateHandler(() => {});
  };

  return (
    <Screen
      name="Referral Screen"
      headerProps={{
        back: { to: '/' },
        title: <Screen.GagglTitle />,
      }}
      hideToolbar
      disableLocationPermissionGate
    >
      <Screen.Content maxWidth={425}>
        {/* <Card>
          <img src={headerImage} alt="logo" />
        </Card> */}

        <TitleToolbar text="Earn $5 for Every Referral!" />
        <p>
          Help grow the Gaggl community and get rewarded! 🌟 With our referral program, you’ll earn
          $5 for every new drag performer you refer. Just share your referral link and watch your
          rewards add up—earn over $250 while building an incredible network of talent! 🎉
        </p>

        {!entertainer && (
          <Fragment key="unauthorized-actions">
            {userIsLoggedIn ? (
              <EmptyState
                title="You are not eligible to participate"
                content="You must be an active drag entertainer to participate in the badge rewards program. You can create your entertainer profile in the app."
                button={{
                  text: 'Return To App',
                  onClick: () => {
                    track('Click Return To App');
                    navigate('/', {
                      animate: false,
                    });
                  },
                }}
              />
            ) : (
              <Button roundness="rounded" onClick={handleEnter}>
                Get Started
              </Button>
            )}
          </Fragment>
        )}

        {entertainer && (
          <Fragment key="authorized-actions">
            <TitleToolbar text="How It Works:" size="md" />

            <TitleToolbar text="Share Your Referral Link" size="md" />

            <AvailabilityReferralInput
              availabilityReferralUrl={entertainer?.availabilityReferralUrl}
            />
            <p>Use your unique referral link to invite other drag performers to join. You can:</p>
            <ul>
              <li>Send it via text</li>
              <li>Post it to your story</li>
              <li>Add it to your bio</li>
            </ul>
            <p>
              The more performers you bring into the community, the bigger your rewards. Help grow
              the Gaggl family and get paid for it! 🌟
            </p>

            <div style={{}}>
              <hr style={{ margin: '48px 0' }} />
              <TitleToolbar text="Werq Smarter, Not Harder" size="md" />
              <p>
                <strong>How to Update Your Instagram Bio Link</strong>
              </p>

              {profileExampleIsVisible ? (
                <Swiper pagination={true} modules={[Pagination, Navigation]}>
                  {[profileSlide1, profileSlide2, profileSlide3, profileSlide4].map((slide) => (
                    <Swiper.Slide>
                      <div>
                        <img
                          src={slide}
                          style={{ display: 'block', margin: '0 auto', width: 180, height: 'auto' }}
                        />
                      </div>
                    </Swiper.Slide>
                  ))}
                </Swiper>
              ) : (
                <Button
                  color="neutral"
                  variant="smooth"
                  roundness="rounded"
                  onClick={() => {
                    track('Click Profile Example');
                    setProfileExampleIsVisible(true);
                  }}
                >
                  View Example
                </Button>
              )}

              <ol>
                <li>Go to your Instagram profile and tap “Edit Profile.”</li>
                <li>Click the "Link" field, and paste your unique referral link.</li>
                <li>Tap “Save” and your link is now live in your bio.</li>
              </ol>

              <p style={{ marginTop: 4 * 12 }}>
                <strong>How to Share Your Referral Link in a Story</strong>
              </p>

              {storyExampleIsVisible ? (
                <Swiper pagination={true} modules={[Pagination, Navigation]}>
                  {[storySlide1, storySlide2, storySlide3].map((slide) => (
                    <Swiper.Slide>
                      <div>
                        <img
                          src={slide}
                          style={{ display: 'block', margin: '0 auto', width: 180, height: 'auto' }}
                        />
                      </div>
                    </Swiper.Slide>
                  ))}
                </Swiper>
              ) : (
                <Button
                  color="neutral"
                  variant="smooth"
                  roundness="rounded"
                  onClick={() => {
                    track('Click Story Example');
                    setStoryExampleIsVisible(true);
                  }}
                >
                  View Example
                </Button>
              )}

              <ol>
                <li>
                  Create a new Instagram Story with a photo or video showcasing your latest look.
                </li>
                <li>
                  Tap the Sticker Icon (between "Aa" and the Music Icon) at the top of the Story
                  editor.
                </li>
                <li>Tap the Link Icon (chain icon) in the list.</li>
                <li>Paste your referral link and add it to your Story.</li>
                <li>Include a call-to-action like: “Share a Badge”</li>
                <li>Post the Story and watch the badges roll in!</li>
              </ol>
            </div>

            <div style={{ display: 'none', marginTop: 4 * 36, fontSize: '.8rem' }}>
              <hr />
              <p>
                <em>
                  * Rewards are capped at $100 for $5 badges each month across all participants.
                  Once the cap is reached, badges will still earn $0.50 each for the remainder of
                  the month.
                </em>
              </p>
            </div>
          </Fragment>
        )}
      </Screen.Content>
      <ErrorBoundary>
        <OnboardEntertainerPopup
          isVisible={isEntertainerOnboardingPopupVisible}
          onClose={() => {
            track('Close onboard entertainer popup');
            setIsEntertainerOnboardingPopupVisible(false);
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        {entertainer && (
          <EditEntertainerPopup
            isVisible={isEditPopupVisible}
            onClose={() => {
              setIsEditPopupVisible(false);
            }}
          />
        )}
      </ErrorBoundary>
    </Screen>
  );
};
