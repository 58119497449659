import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import * as dayjs from 'dayjs';
import { capitalize } from 'lodash';

import {
  EntertainerBookingOpportunity,
  useCurrentEntertainerQuery,
  useFetchCurrentEntertainer,
} from '@queries/EntertainerQueries';
import {
  BookingOpportunityEntertainer,
  useBookingOpportunityQuery,
} from '@queries/PromoterQueries';
import { useIsValidTokenQuery } from '@queries/UserQueries';

import {
  RespondToBookingOpportunityMutationPayload,
  useRespondToBookingOpportunityMutation,
} from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useShareLink } from '@hooks/useShareLink';

import { useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { AuthPopup, useAuthState } from '@screens/LogInScreen/LogInScreen';
import {
  BookingOpportunityDetailQuestionsSection,
  BookingOpportunityUpdatesSection,
  ShareBookingOpportunityButton,
} from '@screens/promoter/ManagePromoterBookingOpportunityScreen/ManagePromoterBookingOpportunityScreen';

type ClickOverride = (cb: () => void) => () => void;

const StatusActionButton = ({
  bookingOpportunity,
  onClickOverride,
}: {
  bookingOpportunity: EntertainerBookingOpportunity;
  onClickOverride?: ClickOverride;
}) => {
  const { track } = useAnalytics();
  const [status, setStatus] = useState(bookingOpportunity.status);
  const { isSubmitting, respondToBookingOpportunityAsync } =
    useRespondToBookingOpportunityMutation();
  const handleUpdateBookingOpportunityInterest = async ({
    status: updatedStatus,
  }: {
    status: RespondToBookingOpportunityMutationPayload['status'];
  }) => {
    track('Booking Response Click', {
      status: updatedStatus,
    });

    if (onClickOverride) {
      onClickOverride(async () => {
        setStatus(updatedStatus);
        await respondToBookingOpportunityAsync({
          bookingOpportunityId: bookingOpportunity._id,
          status: updatedStatus,
        });
      })();
      return;
    }

    setStatus(updatedStatus);
    await respondToBookingOpportunityAsync({
      bookingOpportunityId: bookingOpportunity._id,
      status: updatedStatus,
    });
  };

  if (['confirmed', 'rejected'].includes(status)) {
    return (
      <Button disabled roundness="rounded" variant="outlined" color="neutral">
        {capitalize(status)}
      </Button>
    );
  }

  return (
    <div className="d-flex gap-2 w-100">
      <div className="w-50">
        <Button
          disabled={isSubmitting}
          noWrap
          roundness="rounded"
          color="neutral"
          variant={status === 'notInterested' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon
              faStyle="far"
              name={status === 'notInterested' ? 'times-circle' : 'circle'}
            />
          }
          onClick={() =>
            handleUpdateBookingOpportunityInterest({
              status: 'notInterested',
            })
          }
        >
          Not interested
        </Button>
      </div>
      <div className="w-50">
        <Button
          noWrap
          roundness="rounded"
          disabled={isSubmitting}
          variant={status === 'interested' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon
              faStyle="far"
              name={status === 'interested' ? 'check-circle' : 'circle'}
            />
          }
          onClick={() =>
            handleUpdateBookingOpportunityInterest({
              status: 'interested',
            })
          }
        >
          Interested
        </Button>
      </div>
    </div>
  );
};

export const ManageEntertainerBookingOpportunityScreen: FC<{}> = () => {
  const { bookingOpportunityId } = useParams<{ bookingOpportunityId?: string }>();
  const navigate = useNavigate();
  const { entertainerIsReady, entertainer, bookingOpportunities } = useCurrentEntertainerQuery();
  const bookingOpportunity = useMemo(() => {
    return bookingOpportunities.find(({ _id }) => _id === bookingOpportunityId);
  }, [bookingOpportunities, bookingOpportunityId]);

  return (
    <Screen
      name="Booking Opportunity Details Screen"
      headerProps={{
        back: { to: '/manage/entertainer' },
        title: bookingOpportunity?.promoter?.title,
        right: <ShareBookingOpportunityButton bookingOpportunityId={bookingOpportunityId} />,
      }}
      bottomToolbar={
        <Screen.BottomToolbar>
          {bookingOpportunity && <StatusActionButton bookingOpportunity={bookingOpportunity} />}
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content>
        {entertainerIsReady ? (
          <>
            {bookingOpportunity && (
              <div className="grid">
                {bookingOpportunity.primaryImage && (
                  <Card className="mb-3">
                    <AsyncImage
                      src={bookingOpportunity.primaryImage}
                      alt={bookingOpportunity.title}
                    />
                  </Card>
                )}
                <div>
                  <TitleToolbar className="title-toolbar-welcome" text={bookingOpportunity.title} />
                  <div className="d-flex gap-2">
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="clock" />
                      </span>
                      {dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY')}
                    </div>
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="map-marker" />
                      </span>
                      {`${bookingOpportunity.venue.title}, ${bookingOpportunity.city.name}`}
                    </div>
                  </div>

                  {mapStringToParagraphs(bookingOpportunity.details)}

                  <BookingOpportunityDetailQuestionsSection
                    className="mb-3"
                    mode="ask"
                    bookingOpportunity={bookingOpportunity}
                  />

                  <Button
                    startIcon={<FontAwesomeIcon name="comment" />}
                    roundness="rounded"
                    variant="smooth"
                    color="neutral"
                    className="px-2 mb-4"
                    onClick={() =>
                      navigate(
                        `/chat/entertainer/${entertainer._id}/promoter/${bookingOpportunity?.promoter?._id}`,
                      )
                    }
                  >
                    Message Organizer
                  </Button>

                  <BookingOpportunityUpdatesSection
                    canAddUpdate={false}
                    bookingOpportunity={bookingOpportunity}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <Skeleton height={200} />
        )}
      </Screen.Content>
    </Screen>
  );
};

export const ManageNoauthBookingOpportunityScreen: FC<{}> = () => {
  const [authPopupIsVisible, setAuthPopupIsVisible] = useState(false);
  const authState = useAuthState();
  const { bookingOpportunityId } = useParams<{ bookingOpportunityId?: string }>();
  const navigate = useNavigate();
  const { userIsLoggedIn } = useAppContext();
  const { bookingOpportunityIsReady, bookingOpportunity } =
    useBookingOpportunityQuery(bookingOpportunityId);
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();
  const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
  const [linkBannerIsVisible, setLinkBannerIsVisible] = useState(false);
  const { track } = useAnalytics();

  useEffect(() => {
    if (userIsLoggedIn) {
      fetchCurrentEntertainer().then(({ entertainer }) => {
        if (!entertainer.title) {
          setLinkBannerIsVisible(true);
        }
      });
    }
  }, []);

  const authCallbackRef = useRef<() => void>();
  const handleStatusActionButtonClickOverride: ClickOverride | undefined = !userIsLoggedIn
    ? (cb) => () => {
        setAuthPopupIsVisible(true);
        authCallbackRef.current = cb;
      }
    : undefined;

  const handleMessageCallback = async () => {
    setIsSubmittingResponse(true);

    try {
      await useRespondToBookingOpportunityMutation.mutationFn({
        bookingOpportunityId,
        status: 'interested',
      });
      const { entertainer } = await fetchCurrentEntertainer();
      setIsSubmittingResponse(false);
      navigate(
        `/chat/entertainer/${entertainer._id}/promoter/${bookingOpportunity?.promoter?._id}`,
      );
    } catch (e) {}

    setIsSubmittingResponse(false);
  };
  const handleMessageOrganizer = userIsLoggedIn
    ? handleMessageCallback
    : () => {
        setAuthPopupIsVisible(true);
        authCallbackRef.current = handleMessageCallback;
      };

  const { didCopy, shareLink } = useShareLink();
  const handleShareClick = async () => {
    track('Click share booking opportunity');
    await shareLink({
      url: `https://my.gaggl.app/noauth/booking-opportunity/${bookingOpportunityId}`,
    });
  };

  return (
    <Screen
      name="Booking Opportunity Details Screen (NoAuth)"
      disableLocationPermissionGate
      metaData={{
        title: `${bookingOpportunity?.city?.name} Drag Gig - ${bookingOpportunity?.title} | Powered by Gaggl`,
        description: `View and respond to ${bookingOpportunity?.promoter?.title}'s local drag gig opportunity in ${bookingOpportunity?.city?.name}.`,
        image: 'https://my.gaggl.app/assets/images/drag-gig-opportunity.png',
      }}
      headerProps={{
        title: <Screen.GagglTitle />,
        right: (
          <Button
            variant="smooth"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={
              didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share-square" />
            }
            className="py-2 opacity-90"
            onClick={handleShareClick}
          >
            {didCopy ? 'Copied!' : 'Share'}
          </Button>
        ),
      }}
      bottomToolbar={
        <Screen.BottomToolbar>
          <div style={{ maxWidth: 425, width: '100%', margin: '0 auto' }}>
            {bookingOpportunity && (
              <StatusActionButton
                bookingOpportunity={bookingOpportunity as any}
                onClickOverride={handleStatusActionButtonClickOverride}
              />
            )}
          </div>
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content>
        <div style={{ maxWidth: 425, margin: '0 auto' }}>
          {bookingOpportunityIsReady ? (
            <>
              {bookingOpportunity && (
                <div className="grid">
                  {userIsLoggedIn && linkBannerIsVisible && (
                    <Card
                      className="mb-4"
                      onClick={() => {
                        track('Complete Profile Click');
                        navigate(`/entertainer/onboarding`);
                      }}
                      style={{ background: '#FFC0CB', color: '#9b3d3d' }}
                    >
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="mb-1 fs-6">
                              <strong>Incomplete Profile</strong>
                            </p>
                            <p>
                              Complete your entertainer profile if you want to be booked by this
                              event producer.
                            </p>
                          </div>
                          <div className="ps-2">
                            <FontAwesomeIcon name="chevron-right" />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  )}

                  {bookingOpportunity.primaryImage && (
                    <Card className="mb-3">
                      <AsyncImage
                        src={bookingOpportunity.primaryImage}
                        alt={bookingOpportunity.title}
                      />
                    </Card>
                  )}

                  <div>
                    <TitleToolbar
                      className="mb-0 lh-1"
                      text={bookingOpportunity?.promoter?.title}
                      size="md"
                    />
                    <TitleToolbar
                      className="title-toolbar-welcome"
                      text={bookingOpportunity.title}
                    />
                    <div className="d-flex gap-2">
                      <div>
                        <span className="me-1">
                          <LineAwesomeIcon name="clock" />
                        </span>
                        {dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY')}
                      </div>
                      <div>
                        <span className="me-1">
                          <LineAwesomeIcon name="map-marker" />
                        </span>
                        {`${bookingOpportunity.venue.title}, ${bookingOpportunity.city.name}`}
                      </div>
                    </div>

                    {mapStringToParagraphs(bookingOpportunity.details)}

                    <BookingOpportunityDetailQuestionsSection
                      className="mb-3"
                      mode="ask"
                      bookingOpportunity={bookingOpportunity}
                    />

                    <Button
                      startIcon={<FontAwesomeIcon name="comment" />}
                      disabled={isSubmittingResponse}
                      roundness="rounded"
                      variant="smooth"
                      color="neutral"
                      className="px-2 mb-4"
                      onClick={() => {
                        track('Message Organizer Click');
                        handleMessageOrganizer();
                      }}
                    >
                      Message Organizer
                    </Button>

                    <BookingOpportunityUpdatesSection
                      canAddUpdate={false}
                      bookingOpportunity={bookingOpportunity}
                    />

                    {/* <TitleToolbar text="What's Gaggl?" size="md" />
                    <Card>
                      <Card.Body>
                        <p className="mb-1 fs-6">
                          <strong>Gaggl is a gig management app for drag performers</strong>
                        </p>
                        <p>
                          Gaggl is a platform designed to connect event producers with drag
                          entertainers. Through free booking pages and city-wide text gig
                          notifications, Gaggl makes it easy for drag performers to find and book
                          gigs.
                        </p>
                      </Card.Body>
                    </Card> */}
                  </div>
                </div>
              )}
            </>
          ) : (
            <Skeleton height={200} />
          )}
        </div>
        <AuthPopup
          isVisible={authPopupIsVisible}
          onClose={() => {
            setAuthPopupIsVisible(false);
            authState.setUserId('');
          }}
          redirectOverride={async () => {
            authCallbackRef.current?.();
            const { entertainer } = await fetchCurrentEntertainer();
            if (!entertainer?.title) {
              setLinkBannerIsVisible(true);
            }
          }}
          authMessage="Stay in the loop effortlessly! When you provide your phone number, we’ll send you important updates directly from gig organizers—ensuring you never miss a beat. Your privacy matters to us: your number remains confidential and is solely used for notifications. Ready to get timely updates? Just enter your number below!"
          {...authState}
        />
      </Screen.Content>
    </Screen>
  );
};
