import { ComponentProps, FC } from 'react';

import { useAnalytics } from '@hooks/useAnalytics';
import { useShareLink } from '@hooks/useShareLink';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

import Button from './Button';

export const ShareLinkButton: FC<{
  buttonProps?: Partial<ComponentProps<typeof Button>>;
  url: string;
  track?: {
    event: string;
    properties?: Record<string, any>;
  };
}> = ({ buttonProps, url, track: trackParameters }) => {
  const { track } = useAnalytics();

  const { didCopy, shareLink } = useShareLink();
  const handleShareClick = async () => {
    if (trackParameters) {
      track(trackParameters?.event, trackParameters?.properties);
    }

    await shareLink({
      url,
    });
  };

  return (
    <Button
      variant="smooth"
      roundness="rounded"
      size="sm"
      color="neutral"
      startIcon={
        didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share-square" />
      }
      className="py-2 opacity-90"
      onClick={handleShareClick}
      {...buttonProps}
    >
      {didCopy ? 'Copied!' : 'Share'}
    </Button>
  );
};
